import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import { GoogleOAuthProvider } from "@react-oauth/google"

import Destinations from './routes/Destinations';
import Header from './components/Header';
import Tourpackeges from './routes/Tourpackeges';
import Footer from "./components/Footer";
import Dashboard from './routes/Dashboard';
import Shoppingcart from './routes/Shoppingcart';
import Wishlist from './routes/Wishlist';
import Booking from './routes/Booking';
import Login from './routes/Login';
import Register from './routes/Register';
import Aboutus from './routes/Aboutus';
import Singletour from './routes/Singletour';
import Checkout from './routes/Checkout';
import Contactus from './routes/Contactus';
import PrivateRoute from './components/PrivateRoute';
import PrivateAdminRoute from './components/PrivateAdminRoute';
import AdminLogin from './admin/AdminLogin';
import AdminResistration from './admin/AdminResistration';
import AdminPanel from './admin/AdminPanel';
import Setting from './routes/Setting';
import AddProduct from './routes/AddProduct';
import { useSelector } from 'react-redux';
import Security from './routes/Security';
import ScrollToTop from './components/ScrollToTop';
import BookingCompleted from './routes/BookingCompleted';


function App() {
  // const [loggedIn, setLoggedIn] = useState(false);

  const loggedIn = useSelector((state) => state.user.isLoggedIn);
  const adminLoggedIn = useSelector((state) => state.admin.isAdminLoggedIn);
  console.log(loggedIn);
  // console.log(adminLoggedIn);

  return (
    <GoogleOAuthProvider clientId="821107050229-gh8ebq4ipt2u615mj0i3rg6vp734054i.apps.googleusercontent.com">

      <Router>
        <Header loggedIn={loggedIn} adminLoggedIn={adminLoggedIn} />
        <ScrollToTop />

        <Routes>
          {/* Public Routes */}
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/adminregister" element={<AdminResistration />} />

          {/* Admin Protected Routes */}
          <Route path="/adminpanel" element={
            <PrivateAdminRoute adminLoggedIn={adminLoggedIn}>
              <AdminPanel />
            </PrivateAdminRoute>
          } />

          {/* User Routes */}
          <Route path="/" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/login" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Login />
            </PrivateRoute>
          } />
          <Route path="/register" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Register />
            </PrivateRoute>
          } />
          <Route path="/destinations" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Destinations />
            </PrivateRoute>
          } />
          <Route path="/tour-packeges" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Tourpackeges />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Dashboard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/setting" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Setting adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/shopping-cart" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Shoppingcart />
            </PrivateRoute>
          } />
          <Route path="/wishlist" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Wishlist adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/booking" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Booking adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/security" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Security adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
          <Route path="/about-us" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Aboutus />
            </PrivateRoute>
          } />
          <Route path="/single-tour" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Singletour />
            </PrivateRoute>
          } />
          <Route path="/checkout" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Checkout />
            </PrivateRoute>
          } />
          <Route path="/booking-completed" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <BookingCompleted />
            </PrivateRoute>
          } />
          <Route path="/contact-us" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <Contactus />
            </PrivateRoute>
          } />
          <Route path="/addproduct" element={
            <PrivateRoute loggedIn={loggedIn} adminLoggedIn={adminLoggedIn}>
              <AddProduct adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} />
            </PrivateRoute>
          } />
        </Routes>
        <Footer />
      </Router>
    </GoogleOAuthProvider>

  );
}

export default App;
