import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import adminReducer from "./adminSlice"
import productReducer from "./productSlice"

const store = configureStore({
    reducer: {
        user: userReducer,
        admin: adminReducer,
        product: productReducer
    },
});

export default store;
