// src/components/UserProfileCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserProfileCard = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {
    const userData = useSelector((state) => state.user.userData);
    const adminData = useSelector((state) => state.admin.adminData);

    // Helper function to determine if the picture is an external URL
    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : `/images/profileImage/${picture}`;
    };

    return (
        <div className="card-body">
            <div className="account-user border-bottom pb-4 mb-3">
                <form method="post" className="user-avatar">
                    <img
                        src={
                            adminLoggedIn
                                ? getImageSrc(adminData.picture)
                                : getImageSrc(userData.picture)
                        }
                        alt="User Avatar"
                        className="show-avatar rounded-circle"
                    />
                    <label htmlFor="filAvatar" className="select-avatar">
                        <i className="hicon hicon-camera-hover"></i>
                    </label>
                    <button type="submit" className="btn btn-primary btn-update-avatar d-none">
                        <i className="hicon hicon-check-valid-state"></i>
                    </button>
                    <input className="input-avatar d-none" type="file" id="filAvatar" />
                </form>
                <div className="username">
                    <span>Hi!</span>
                    <strong>
                        {adminLoggedIn ? ' Admin ' + adminData.fullname : userData.fullname}
                    </strong>
                </div>
            </div>
            <ul className="account-menu fw-medium mb-0">
                <li className="menu-item active">
                    <Link to="/dashboard">
                        <i className="hicon hicon-bold hicon-ycs-dashboard"></i> <span>Dashboard</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to="/booking">
                        <i className="hicon hicon-bold hicon-installment-payment"></i> <span>Booking</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to="/wishlist">
                        <i className="hicon hicon-bold hicon-menu-favorite"></i> <span>Wishlist</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to="/setting">
                        <i className="hicon hicon-bold hicon-luggage"></i> <span>Setting</span>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to="/security">
                        <i className="hicon hicon-bold hicon-aps-lock"></i> <span>Security</span>
                    </Link>
                </li>
                {adminLoggedIn && (
                    <li className="menu-item">
                        <Link to="/addproduct">
                            <i className="hicon hicon-bold hicon-aps-lock"></i> <span>Add Product</span>
                        </Link>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default UserProfileCard;
