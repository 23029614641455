import React, { useEffect } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { Link } from 'react-router-dom';

const Topdestination = () => {
    useEffect(() => {
        const splide = new Splide('#destination-slider', {
            perPage: 4,
            gap: '1.5rem',
            padding: 0,
            speed: 1000, // Improved readability by changing 1e3 to 1000
            type: 'loop',
            focus: 0,
            breakpoints: {
                1200: {
                    perPage: 1,
                    padding: { left: 0, right: '280px' },
                    arrows: false,
                },
                992: {
                    perPage: 1,
                    padding: { left: 0, right: '250px' },
                    arrows: false,
                },
                576: {
                    perPage: 1,
                    gap: '0.75rem',
                    padding: 0,
                    arrows: false,
                },
            },
        });

        splide.on('mounted', () => {
            const destinationSlider = document.getElementById('destination-slider');
            const btnArrowLeft = document.getElementById('btnarrowl');
            const btnArrowRight = document.getElementById('btnarrowr');

            if (destinationSlider) destinationSlider.style.visibility = 'visible';

            const setArrowStyles = (btn) => {
                btn.style.backgroundColor = '#0a3e59';
                btn.style.height = '40px';
                btn.style.width = '40px';
            };

            if (btnArrowLeft) setArrowStyles(btnArrowLeft);
            if (btnArrowRight) setArrowStyles(btnArrowRight);
        });

        splide.mount();
    }, []);

    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div className="container">
                <div className="destination splide__pagination__start splide" id="destination-slider">
                    <div className="d-xl-flex align-items-xl-center mb-3">
                        <div className="block-title me-auto">
                            <small className="sub-title">Many tourists visit</small>
                            <h2 className="h1 title">Top destinations</h2>
                        </div>
                        <div className="d-lg-flex align-items-lg mt-3">
                            <div className="extra-info me-5">
                                <strong>+50</strong>
                                <span>Attractive destinations</span>
                            </div>
                            <div className="splide__arrows splide__arrows__right">
                                <button
                                    className="splide__arrow splide__arrow--prev me-2"
                                    id="btnarrowl"
                                >
                                    <i className="hicon hicon-edge-arrow-left"></i>
                                </button>
                                <button
                                    className="splide__arrow splide__arrow--next"
                                    id="btnarrowr"
                                >
                                    <i className="hicon hicon-edge-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list">
                            {/* Slide 1 */}
                            <li className="splide__slide">
                                <Link to="/tour-packeges" className="bottom-overlay hover-effect rounded">
                                    <figure className="image-hover image-hover-overlay mb-0">
                                        <img
                                            src="assets/img/destinations/d6.jpg"
                                            srcSet="assets/img/destinations/d6@2x.jpg 2x"
                                            alt="Noriva"
                                        />
                                        <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                    </figure>
                                    <div className="bottom-overlay-content">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column me-auto">
                                                <h3 className="mb-0">Noriva</h3>
                                                <span>176 tours</span>
                                            </div>
                                            <span className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-pin"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            {/* Slide 2 */}
                            <li className="splide__slide">
                                <Link to="/tour-packeges" className="bottom-overlay hover-effect rounded">
                                    <figure className="image-hover image-hover-overlay mb-0">
                                        <img
                                            src="assets/img/destinations/d2.jpg"
                                            srcSet="assets/img/destinations/d2@2x.jpg 2x"
                                            alt="Kardal"
                                        />
                                        <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                    </figure>
                                    <div className="bottom-overlay-content">
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column me-auto">
                                                <h3 className="mb-0">Kardal</h3>
                                                <span>127 tours</span>
                                            </div>
                                            <span className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-pin"></i>
                                            </span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                            {/* Additional slides */}
                            {/* ... */}
                        </ul>
                    </div>
                </div>
                <p className="fw-normal text-secondary text-md-end mb-0 pt-4 mt-5 mt-md-0" data-aos="fade">
                    <Link to="/destinations" className="fw-medium">
                        <span>View all destinations</span>
                        <i className="hicon hicon-flights-one-ways"></i>
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default Topdestination;
