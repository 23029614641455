import React from 'react'
import { Link } from 'react-router-dom'

const Singletourreview = () => {
    return (
        <section id="reviews" class="p-top-90 p-bottom-90 bg-light-gray-gradient">
            <div class="container">
                {/* <!-- Title --> */}
                <div class="block-title me-auto pb-3" data-aos="fade">
                    <small class="sub-title">Genuine Reviews</small>
                    <h2 class="h1 title">Customer reviews</h2>
                </div>
                {/* <!-- Title --> */}
                {/* <!-- Review list --> */}
                <div class="row g-0">
                    <div class="col-12 col-xl-4">
                        <div class="pe-xl-5">
                            <div class="row">
                                <div class="col-12 col-xl-12 col-lg-6">
                                    {/* <!-- Overall rating --> */}
                                    <div class="horizontal-review rounded shadow-sm mb-4" data-aos="fade">
                                        <h2 class="h4 review-title pb-4 mb-4 lh-base">Overall rating</h2>
                                        <div class="review-content">
                                            <h3 class="review-score">4.9</h3>
                                            <div class="review-total">
                                                <span class="star-rate-view"><span class="star-value rate-50"></span></span>
                                                <span><strong class="text-body">2,394</strong> reviews</span>
                                            </div>
                                        </div>
                                        <div class="review-label">
                                            <small>Excellent</small>
                                        </div>
                                    </div>
                                    {/* <!-- /Overall rating --> */}
                                </div>
                                <div class="col-12 col-xl-12 col-lg-6">
                                    {/* <!-- Review summary --> */}
                                    <div class="review-summary rounded shadow-sm mb-4" data-aos="fade">
                                        <h3 class="h4 review-title">Review summary</h3>
                                        <ul class="review-content">
                                            <li>
                                                <span class="review-lable">Transportation:</span>
                                                <div class="review-score">
                                                    <strong>4.5</strong>
                                                    <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                                </div>
                                            </li>
                                            <li>
                                                <span class="review-lable">Value for money:</span>
                                                <div class="review-score">
                                                    <strong>4.5</strong>
                                                    <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                                </div>
                                            </li>
                                            <li>
                                                <span class="review-lable">Support services:</span>
                                                <div class="review-score">
                                                    <strong>4.5</strong>
                                                    <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                                </div>
                                            </li>
                                            <li>
                                                <span class="review-lable">Organization:</span>
                                                <div class="review-score">
                                                    <strong>4.0</strong>
                                                    <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-40"></span></span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- /Review summary --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-8">
                        {/* <!-- Review list --> */}
                        <div class="card border-0 rounded shadow-sm mb-4" data-aos="fade">
                            <div class="card-body">
                                <div class="border-bottom d-flex align-items-center pb-4 mb-4">
                                    <h3 class="h4 me-auto mb-0">Recent reviews</h3>
                                    <strong>2,394 reviews</strong>
                                </div>
                                <div class="review-list">
                                    <div class="review-item">
                                        <div class="review-client">
                                            <figure class="review-avatar">
                                                <img src="./assets/img/avatars/a1.jpg" srcset="assets/img/avatars/a1@2x.jpg 2x" alt="" class="rounded-circle" />
                                            </figure>
                                            <div class="review-name">
                                                <strong>John Doe <small>(Italy)</small></strong>
                                                <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                            </div>
                                        </div>
                                        <p>
                                            "The 5-day tour of Noriva was fantastic! Stunning landscapes, well-organized activities, and friendly guides made the trip unforgettable. Highly recommend!"
                                        </p>
                                        <div class="review-date">
                                            <small>February 9, 2023</small> - <span class="review-verify">Verified booking</span>
                                        </div>
                                    </div>
                                    <div class="review-item">
                                        <div class="review-client">
                                            <figure class="review-avatar">
                                                <img src="./assets/img/avatars/a2.jpg" srcset="assets/img/avatars/a2@2x.jpg 2x" alt="" class="rounded-circle" />
                                            </figure>
                                            <div class="review-name">
                                                <strong>Emily Smith <small>(USA)</small></strong>
                                                <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                            </div>
                                        </div>
                                        <p>
                                            "An incredible journey through Noriva! From breathtaking mountain views to exciting water sports, every day was packed with adventure and beauty. A must-visit!"
                                        </p>
                                        <div class="review-date">
                                            <small>February 9, 2023</small> - <span class="review-verify">Verified booking</span>
                                        </div>
                                    </div>
                                    <div class="review-item">
                                        <div class="review-client">
                                            <figure class="review-avatar">
                                                <img src="./assets/img/avatars/a3.jpg" srcset="assets/img/avatars/a3@2x.jpg 2x" alt="" class="rounded-circle" />
                                            </figure>
                                            <div class="review-name">
                                                <strong>Ariol Deep <small>(France)</small></strong>
                                                <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                            </div>
                                        </div>
                                        <p>
                                            "Our trip to Noriva exceeded expectations. The mix of scenic hikes, relaxing lakeside days, and cultural experiences made for a perfect vacation. Will definitely return!"
                                        </p>
                                        <div class="review-date">
                                            <small>February 9, 2023</small> - <span class="review-verify">Verified booking</span>
                                        </div>
                                    </div>
                                    <div class="review-item">
                                        <div class="review-client">
                                            <figure class="review-avatar">
                                                <img src="./assets/img/avatars/a5.jpg" srcset="assets/img/avatars/a5@2x.jpg 2x" alt="" class="rounded-circle" />
                                            </figure>
                                            <div class="review-name">
                                                <strong>Emma Ross <small>(UK)</small></strong>
                                                <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                            </div>
                                        </div>
                                        <p>
                                            "I loved every moment of this Noriva tour. The guided hikes, serene lakes, and exploration of tropical forests were highlights. Excellent value and great memories!"
                                        </p>
                                        <div class="review-date">
                                            <small>February 9, 2023</small> - <span class="review-verify">Verified booking</span>
                                        </div>
                                    </div>
                                    <div class="review-item">
                                        <div class="review-client">
                                            <figure class="review-avatar">
                                                <img src="./assets/img/avatars/a4.jpg" srcset="assets/img/avatars/a4@2x.jpg 2x" alt="" class="rounded-circle" />
                                            </figure>
                                            <div class="review-name">
                                                <strong>David Kane <small>(Germany)</small></strong>
                                                <span class="star-rate-view star-rate-size-sm"><span class="star-value rate-45"></span></span>
                                            </div>
                                        </div>
                                        <p>
                                            "Fantastic 5-day itinerary in Noriva! Enjoyed everything from mountain climbs to cultural experiences. Well-organized, beautiful scenery, and a truly memorable trip."
                                        </p>
                                        <div class="review-date">
                                            <small>February 9, 2023</small> - <span class="review-verify">Verified booking</span>
                                        </div>
                                    </div>
                                </div>
                                <nav aria-label="Page navigation" class="text-center">
                                    <ul class="pagination pagination-circle d-inline-flex mb-0">
                                        <li class="page-item">
                                            <Link class="page-link" to="#">
                                                <i class="hicon hicon-edge-arrow-left"></i>
                                            </Link>
                                        </li>
                                        <li class="page-item"><Link class="page-link" to="#">1</Link></li>
                                        <li class="page-item">
                                            <Link class="page-link" to="#">
                                                <i class="hicon hicon-edge-arrow-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        {/* <!-- Review list --> */}
                    </div>
                </div>
                {/* <!-- Review list --> */}
            </div>
        </section>)
}

export default Singletourreview