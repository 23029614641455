import React from 'react'

const Tourpackegestitle = () => {
    return (
        <section className="hero" data-aos="fade">
            <div className="hero-bg">
                <img src="./assets/img/tours/t15.jpg" srcSet="./assets/img/tours/t15@2x.jpg 2x" alt="" />
            </div>
            <div className="container">
                <div className=" p-top-180 p-bottom-180">
                    <span className="hero-sub-title">Hidden Charm</span>
                    <h1 className="display-4 hero-title mb-4">
                        Noriva tours
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><a href="./index.html">Home</a></li>
                            <li className="breadcrumb-item"><a href="./index.html">Moliva Tours</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Tour packages</li>
                        </ol>
                    </nav>
                </div>
                <div className="title-info">
                    <div className="title-info-body">
                        <div className="horizontal-review rounded-top">
                            <h2 className="h3 review-title">Overall rating</h2>
                            <div className="review-content">
                                <h3 className="review-score">4.9</h3>
                                <div className="review-total">
                                    <span className="star-rate-view"><span className="star-value rate-50"></span></span>
                                    <span><strong className="text-body">2,394</strong> reviews</span>
                                </div>
                            </div>
                            <div className="review-label">
                                <small>Excellent</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default Tourpackegestitle