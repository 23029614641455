import React from 'react'
import { Link } from 'react-router-dom'

const Tourtypes = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div className="container">
                <div className="d-xl-flex align-items-xl-center mb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Flexible tours</small>
                        <h2 className="h1 title">Explore your way</h2>
                    </div>
                    <div className="fw-normal text-secondary mt-3">
                        You need <a href="./contact.html"><abbr title="Go to contact page" className="fw-semibold">advice</abbr></a>?
                    </div>
                </div>

                <div className="row g-3 g-md-4">
                    <div className="col-12 col-xl-4">
                        <a href="./tours" className="info-card card-hover hover-effect shadow-sm rounded">
                            <div className="card-icon">
                                <i className="hicon hicon-family-with-teens"></i>
                            </div>
                            <h3 className="h4 card-title">Group tours</h3>
                            <p className="card-desc">Join our group tours to explore stunning destinations with like-minded travelers. Fun and camaraderie guaranteed.</p>
                            <span className="card-link">
                                <span>View tours</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </span>
                        </a>
                    </div>
                    <div className="col-12 col-xl-4">
                        <Link to="/tour-packeges" className="info-card card-hover active hover-effect shadow-sm rounded">
                            <div className="card-icon">
                                <i className="hicon hicon-regular-travel-protection"></i>
                            </div>
                            <h3 className="h4 card-title">Private tours</h3>
                            <p className="card-desc">Enjoy personalized experiences with our private tours. Perfect for families, couples, or friends seeking exclusivity.</p>
                            <span className="card-link">
                                <span>View tours</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </span>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4">
                        <a href="./contact.html" className="info-card card-hover hover-effect shadow-sm rounded">
                            <div className="card-icon">
                                <i className="hicon hicon-tours"></i>
                            </div>
                            <h3 className="h4 card-title">Tailor-Made tours</h3>
                            <p className="card-desc">Customize your dream vacation with our tailor-made tours. Create unique itineraries that suit your interests and schedule.</p>
                            <span className="card-link">
                                <span>Contact us</span>
                                <i className="hicon hicon-flights-one-ways"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tourtypes