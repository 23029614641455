import React from 'react'
import { Link } from 'react-router-dom'

const Categories = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div className="container">
                <div className="d-xl-flex align-items-xl-center pb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Tour categories</small>
                        <h2 className="h1 title">Adventure types</h2>
                    </div>
                    <div className="fw-normal text-secondary mt-3">
                        You need <a href="./contact.html"><abbr title="Go to contact page" className="fw-semibold">advice</abbr></a>?
                    </div>
                </div>

                <div className="row g-3 g-md-4">
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-regular-valley hicon-250"></i>
                            </span>
                            <div className="card-content">
                                <h3 className="h5 card-title">Ecotourism & Resort</h3>
                                <small className="card-desc">124 tours</small>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-meal hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Cultural & Foods</h3>
                                    <small className="card-desc">231 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-mountain-view hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Explorer & Adventure</h3>
                                    <small className="card-desc">311 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-culture hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Festival & Events</h3>
                                    <small className="card-desc">219 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-mountain-view hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Museums & Exhibitions</h3>
                                    <small className="card-desc">189 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-hiking hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Hiking & Trekking</h3>
                                    <small className="card-desc">167 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-bicycle-rental hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Motor & Bicycles</h3>
                                    <small className="card-desc">112 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-regular-yachting hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Ships & boats</h3>
                                    <small className="card-desc">301 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6">
                        <Link to="/tour-packeges" className="mini-card card-hover hover-effect shadow-sm rounded">
                            <div className="d-flex align-items-center">
                                <span className="card-icon">
                                    <i className="hicon hicon-family-with-teens hicon-250"></i>
                                </span>
                                <div className="card-content">
                                    <h3 className="h5 card-title">Family Activities</h3>
                                    <small className="card-desc">211 tours</small>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Categories