import React, { useEffect, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProducts } from '../redux/productSlice'

const Checkout = () => {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const { products, loading } = useSelector((state) => state.product);
    const dataLoadedRef = useRef(false);

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && products.length > 0 && !dataLoadedRef.current) {
            // console.log("All data loaded:", products);
            dataLoadedRef.current = true;
        }
    }, [loading, products])

    const userProducts = useMemo(() => {
        if (!userData || !userData.orders || !products) return [];
        const orderProductIds = userData.orders.map(order => order.product);
        return products.filter(product => orderProductIds.includes(product._id));
    }, [userData, products]);


    const totalPrice = useMemo(() => {
        return userProducts.reduce((acc, product) => acc + product.price, 0);
    }, [userProducts]);




    return (
        <div className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">

            {/* <!-- Title --> */}
            <section className="container">
                <div className="d-lg-flex align-items-lg-end pb-4">
                    <div className="block-title me-auto">
                        <small className="sub-title">Booking</small>
                        <h1 className="display-5 title">Checkout</h1>
                    </div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mt-3">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                        </ol>
                    </nav>
                </div>
                <div className="booking-process fw-normal mb-5" data-booking-step="2">
                    <span className="circle-icon circle-icon-sm booking-step step-1">1</span>
                    <span className="circle-icon circle-icon-sm booking-step step-2">2</span>
                    <span className="circle-icon circle-icon-sm booking-step step-3">3</span>
                </div>
            </section>
            {/* <!-- Title --> */}

            {/* <!-- Checkout --> */}
            <section className="container">
                <div className="row g-0 g-xl-5">
                    <div className="col-12 col-xl-4">
                        {/* <!-- Selected tours --> */}
                        <div className="card border-0 shadow-sm mb-4" data-aos="fade">
                            <div className="card-body">
                                <div className="border-bottom pb-4 mb-4">
                                    <h2 className="h3 ff-primary mb-0 text-body-emphasis">1. Selected tours</h2>
                                </div>
                                {
                                    userProducts.map((product) => {
                                        const productID = product._id;
                                        const orderData = userData.orders.filter(order => order.product === productID); // Accessing data from userData
                                        const date = new Date(orderData[0].departureDate);
                                        const options = { year: 'numeric', month: 'short', day: '2-digit' };
                                        const formattedDate = date.toLocaleDateString('en-US', options);
                                        return (
                                            <div className="border-bottom pb-4 mb-4">
                                                <h3 className="h5 mb-2">
                                                    <Link to={`/single-tour/${productID}`} className="link-dark link-hover">
                                                        {product.name}
                                                    </Link>                                            </h3>
                                                <div className="mb-2">
                                                    <small className="me-2"><i className="hicon hicon-menu-calendar"></i> {product.iteracy.curriculum.length}  days</small>
                                                    <small className="me-2"><i className="hicon hicon-flights-pin"></i> {product.about.highlight.length} Destinations</small>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <small className="mb-2">Tour date: <strong>{formattedDate}</strong></small>
                                                    <small className="mb-2">Guest: <strong>{orderData[0].adults} Adults - {orderData[0].children} Children</strong></small>
                                                </div>
                                                <div className="mt-2">
                                                    <strong className="fs-4 fw-semibold"><sup>$</sup>{product.price}</strong>
                                                    <span className="fsm-4 text-secondary"><sup>$</sup><del className="">{product.price + 100}</del></span>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                                <div className="mt-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <strong className="fs-5">Total {userProducts.length} tours:</strong>
                                        <span className="fs-2 fw-semibold text-body-emphasis"><sup>$</sup>{totalPrice}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- /Selected tours --/> */}
                    </div>
                    <div className="col-12 col-xl-8">
                        {/* <!-- Booking & Payment -/-> */}
                        <div className="card border-0 shadow-sm sticky-top sticky-top-120 z-0" data-aos="fade">
                            <div className="card-body">
                                <div className="border-bottom pb-4 mb-4">
                                    <h2 className="h3 ff-primary mb-0 text-body-emphasis">2. Booking & Payment</h2>
                                </div>
                                <form method="post">
                                    <div className="border-bottom pb-4 mb-4">
                                        <h3 className="h4 mb-4">Your information</h3>
                                        <div className="row g-3">
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtFirtname">Firt Name<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control shadow-sm" id="txtFirtname" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtLastname">Last Name<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control shadow-sm" id="txtLastname" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtPhone">Phone<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control shadow-sm" id="txtPhone" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtEmail">Email<span className="text-danger">*</span></label>
                                                    <input type="email" className="form-control shadow-sm" id="txtEmail" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtAddress">Address<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control shadow-sm" id="txtAddress" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtCity">City<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control shadow-sm" id="txtCity" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="txtPostCode">Post Code<span className="text-danger">*</span></label>
                                                    <input type="text" className="form-control shadow-sm" id="txtPostCode" required />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-2">
                                                    <label className="form-label" for="selCountry">Country<span className="text-danger">*</span></label>
                                                    <select className="form-select dropdown-select shadow-sm" id="selCountry" data-dropdown-select-search="true" required>
                                                        <option value="">
                                                            Select
                                                        </option>
                                                        <option value="AF">
                                                            Afghanistan
                                                        </option>
                                                        <option value="AX">
                                                            Åland Islands
                                                        </option>
                                                        <option value="AL">
                                                            Albania
                                                        </option>
                                                        <option value="DZ">
                                                            Algeria
                                                        </option>
                                                        <option value="AS">
                                                            American Samoa
                                                        </option>
                                                        <option value="AD">
                                                            Andorra
                                                        </option>
                                                        <option value="AO">
                                                            Angola
                                                        </option>
                                                        <option value="AI">
                                                            Anguilla
                                                        </option>
                                                        <option value="AG">
                                                            Antigua and Barbuda
                                                        </option>
                                                        <option value="AR">
                                                            Argentina
                                                        </option>
                                                        <option value="AM">
                                                            Armenia
                                                        </option>
                                                        <option value="AW">
                                                            Aruba
                                                        </option>
                                                        <option value="AU">
                                                            Australia
                                                        </option>
                                                        <option value="AT">
                                                            Austria
                                                        </option>
                                                        <option value="AZ">
                                                            Azerbaijan
                                                        </option>
                                                        <option value="BS">
                                                            Bahamas
                                                        </option>
                                                        <option value="BH">
                                                            Bahrain
                                                        </option>
                                                        <option value="BD">
                                                            Bangladesh
                                                        </option>
                                                        <option value="BB">
                                                            Barbados
                                                        </option>
                                                        <option value="BY">
                                                            Belarus
                                                        </option>
                                                        <option value="BE">
                                                            Belgium
                                                        </option>
                                                        <option value="BZ">
                                                            Belize
                                                        </option>
                                                        <option value="BJ">
                                                            Benin
                                                        </option>
                                                        <option value="BM">
                                                            Bermuda
                                                        </option>
                                                        <option value="BT">
                                                            Bhutan
                                                        </option>
                                                        <option value="BO">
                                                            Bolivia
                                                        </option>
                                                        <option value="BQ">
                                                            Bonaire
                                                        </option>
                                                        <option value="BA">
                                                            Bosnia and Herzegovina
                                                        </option>
                                                        <option value="BW">
                                                            Botswana
                                                        </option>
                                                        <option value="BR">
                                                            Brazil
                                                        </option>
                                                        <option value="IO">
                                                            British Indian Ocean Territory
                                                        </option>
                                                        <option value="VG">
                                                            British Virgin Islands
                                                        </option>
                                                        <option value="BN">
                                                            Brunei
                                                        </option>
                                                        <option value="BG">
                                                            Bulgaria
                                                        </option>
                                                        <option value="BF">
                                                            Burkina Faso
                                                        </option>
                                                        <option value="BI">
                                                            Burundi
                                                        </option>
                                                        <option value="KH">
                                                            Cambodia
                                                        </option>
                                                        <option value="CM">
                                                            Cameroon
                                                        </option>
                                                        <option value="CA">
                                                            Canada
                                                        </option>
                                                        <option value="CV">
                                                            Cape Verde
                                                        </option>
                                                        <option value="KY">
                                                            Cayman Islands
                                                        </option>
                                                        <option value="CF">
                                                            Central African Republic
                                                        </option>
                                                        <option value="TD">
                                                            Chad
                                                        </option>
                                                        <option value="CL">
                                                            Chile
                                                        </option>
                                                        <option value="CN">
                                                            China
                                                        </option>
                                                        <option value="CX">
                                                            Christmas Island
                                                        </option>
                                                        <option value="CC">
                                                            Cocos Islands
                                                        </option>
                                                        <option value="CO">
                                                            Colombia
                                                        </option>
                                                        <option value="KM">
                                                            Comoros
                                                        </option>
                                                        <option value="CD">
                                                            Congo-Brazzaville
                                                        </option>
                                                        <option value="CK">
                                                            Cook Islands
                                                        </option>
                                                        <option value="CR">
                                                            Costa Rica
                                                        </option>
                                                        <option value="CI">
                                                            Côte d'Ivoire
                                                        </option>
                                                        <option value="HR">
                                                            Croatia
                                                        </option>
                                                        <option value="CW">
                                                            Curaçao
                                                        </option>
                                                        <option value="CY">
                                                            Cyprus
                                                        </option>
                                                        <option value="CZ">
                                                            Czech Republic
                                                        </option>
                                                        <option value="DK">
                                                            Denmark
                                                        </option>
                                                        <option value="DJ">
                                                            Djibouti
                                                        </option>
                                                        <option value="DM">
                                                            Dominica
                                                        </option>
                                                        <option value="DO">
                                                            Dominican Republic
                                                        </option>
                                                        <option value="EC">
                                                            Ecuador
                                                        </option>
                                                        <option value="EG">
                                                            Egypt
                                                        </option>
                                                        <option value="SV">
                                                            El Salvador
                                                        </option>
                                                        <option value="GQ">
                                                            Equatorial Guinea
                                                        </option>
                                                        <option value="ER">
                                                            Eritrea
                                                        </option>
                                                        <option value="EE">
                                                            Estonia
                                                        </option>
                                                        <option value="ET">
                                                            Ethiopia
                                                        </option>
                                                        <option value="FK">
                                                            Falkland Islands
                                                        </option>
                                                        <option value="FO">
                                                            Faroe Islands
                                                        </option>
                                                        <option value="FJ">
                                                            Fiji
                                                        </option>
                                                        <option value="FI">
                                                            Finland
                                                        </option>
                                                        <option value="FR">
                                                            France
                                                        </option>
                                                        <option value="GF">
                                                            French Guiana
                                                        </option>
                                                        <option value="PF">
                                                            French Polynesia
                                                        </option>
                                                        <option value="GA">
                                                            Gabon
                                                        </option>
                                                        <option value="GM">
                                                            Gambia
                                                        </option>
                                                        <option value="GE">
                                                            Georgia
                                                        </option>
                                                        <option value="DE">
                                                            Germany
                                                        </option>
                                                        <option value="GH">
                                                            Ghana
                                                        </option>
                                                        <option value="GI">
                                                            Gibraltar
                                                        </option>
                                                        <option value="GR">
                                                            Greece
                                                        </option>
                                                        <option value="GL">
                                                            Greenland
                                                        </option>
                                                        <option value="GD">
                                                            Grenada
                                                        </option>
                                                        <option value="GP">
                                                            Guadeloupe
                                                        </option>
                                                        <option value="GU">
                                                            Guam
                                                        </option>
                                                        <option value="GT">
                                                            Guatemala
                                                        </option>
                                                        <option value="GG">
                                                            Guernsey
                                                        </option>
                                                        <option value="GN">
                                                            Guinea
                                                        </option>
                                                        <option value="GW">
                                                            Guinea-Bissau
                                                        </option>
                                                        <option value="GY">
                                                            Guyana
                                                        </option>
                                                        <option value="HT">
                                                            Haiti
                                                        </option>
                                                        <option value="HN">
                                                            Honduras
                                                        </option>
                                                        <option value="HK">
                                                            Hong Kong
                                                        </option>
                                                        <option value="HU">
                                                            Hungary
                                                        </option>
                                                        <option value="IS">
                                                            Iceland
                                                        </option>
                                                        <option value="IN">
                                                            India
                                                        </option>
                                                        <option value="ID">
                                                            Indonesia
                                                        </option>
                                                        <option value="IQ">
                                                            Iraq
                                                        </option>
                                                        <option value="IE">
                                                            Ireland
                                                        </option>
                                                        <option value="IM">
                                                            Isle of Man
                                                        </option>
                                                        <option value="IL">
                                                            Israel
                                                        </option>
                                                        <option value="IT">
                                                            Italy
                                                        </option>
                                                        <option value="JM">
                                                            Jamaica
                                                        </option>
                                                        <option value="JP">
                                                            Japan
                                                        </option>
                                                        <option value="JE">
                                                            Jersey
                                                        </option>
                                                        <option value="JO">
                                                            Jordan
                                                        </option>
                                                        <option value="KZ">
                                                            Kazakhstan
                                                        </option>
                                                        <option value="KE">
                                                            Kenya
                                                        </option>
                                                        <option value="KI">
                                                            Kiribati
                                                        </option>
                                                        <option value="XK">
                                                            Kosovo
                                                        </option>
                                                        <option value="KW">
                                                            Kuwait
                                                        </option>
                                                        <option value="KG">
                                                            Kyrgyz Republic
                                                        </option>
                                                        <option value="LA">
                                                            Laos
                                                        </option>
                                                        <option value="SS">
                                                            Laos
                                                        </option>
                                                        <option value="LV">
                                                            Latvia
                                                        </option>
                                                        <option value="LB">
                                                            Lebanon
                                                        </option>
                                                        <option value="LS">
                                                            Lesotho
                                                        </option>
                                                        <option value="LR">
                                                            Liberia
                                                        </option>
                                                        <option value="LY">
                                                            Libya
                                                        </option>
                                                        <option value="LI">
                                                            Liechtenstein
                                                        </option>
                                                        <option value="LT">
                                                            Lithuania
                                                        </option>
                                                        <option value="LU">
                                                            Luxembourg
                                                        </option>
                                                        <option value="MO">
                                                            Macao
                                                        </option>
                                                        <option value="MG">
                                                            Madagascar
                                                        </option>
                                                        <option value="MW">
                                                            Malawi
                                                        </option>
                                                        <option value="MY">
                                                            Malaysia
                                                        </option>
                                                        <option value="MV">
                                                            Maldives
                                                        </option>
                                                        <option value="ML">
                                                            Mali
                                                        </option>
                                                        <option value="MT">
                                                            Malta
                                                        </option>
                                                        <option value="MH">
                                                            Marshall Islands
                                                        </option>
                                                        <option value="MQ">
                                                            Martinique
                                                        </option>
                                                        <option value="MR">
                                                            Mauritania
                                                        </option>
                                                        <option value="MU">
                                                            Mauritius
                                                        </option>
                                                        <option value="YT">
                                                            Mayotte
                                                        </option>
                                                        <option value="MX">
                                                            Mexico
                                                        </option>
                                                        <option value="FM">
                                                            Micronesia
                                                        </option>
                                                        <option value="MD">
                                                            Moldova
                                                        </option>
                                                        <option value="MC">
                                                            Monaco
                                                        </option>
                                                        <option value="MN">
                                                            Mongolia
                                                        </option>
                                                        <option value="ME">
                                                            Montenegro
                                                        </option>
                                                        <option value="MS">
                                                            Montserrat
                                                        </option>
                                                        <option value="MA">
                                                            Morocco
                                                        </option>
                                                        <option value="MZ">
                                                            Mozambique
                                                        </option>
                                                        <option value="MM">
                                                            Myanmar
                                                        </option>
                                                        <option value="NA">
                                                            Namibia
                                                        </option>
                                                        <option value="NR">
                                                            Nauru
                                                        </option>
                                                        <option value="NP">
                                                            Nepal
                                                        </option>
                                                        <option value="NL">
                                                            Netherlands
                                                        </option>
                                                        <option value="NC">
                                                            New Caledonia
                                                        </option>
                                                        <option value="NZ">
                                                            New Zealand
                                                        </option>
                                                        <option value="NI">
                                                            Nicaragua
                                                        </option>
                                                        <option value="NE">
                                                            Niger
                                                        </option>
                                                        <option value="NG">
                                                            Nigeria
                                                        </option>
                                                        <option value="NU">
                                                            Niue
                                                        </option>
                                                        <option value="NF">
                                                            Norfolk Island
                                                        </option>
                                                        <option value="MK">
                                                            North Macedonia
                                                        </option>
                                                        <option value="MP">
                                                            Northern Mariana Islands
                                                        </option>
                                                        <option value="NO">
                                                            Norway
                                                        </option>
                                                        <option value="OM">
                                                            Oman
                                                        </option>
                                                        <option value="PK">
                                                            Pakistan
                                                        </option>
                                                        <option value="PW">
                                                            Palau
                                                        </option>
                                                        <option value="PS">
                                                            Palestine
                                                        </option>
                                                        <option value="PA">
                                                            Panama
                                                        </option>
                                                        <option value="PG">
                                                            Papua New Guinea
                                                        </option>
                                                        <option value="PY">
                                                            Paraguay
                                                        </option>
                                                        <option value="PE">
                                                            Peru
                                                        </option>
                                                        <option value="PH">
                                                            Philippines
                                                        </option>
                                                        <option value="PL">
                                                            Poland
                                                        </option>
                                                        <option value="PT">
                                                            Portugal
                                                        </option>
                                                        <option value="PR">
                                                            Puerto Rico
                                                        </option>
                                                        <option value="QA">
                                                            Qatar
                                                        </option>
                                                        <option value="CG">
                                                            Republic of the Congo
                                                        </option>
                                                        <option value="RE">
                                                            Réunion
                                                        </option>
                                                        <option value="RO">
                                                            Romania
                                                        </option>
                                                        <option value="RU">
                                                            Russia
                                                        </option>
                                                        <option value="RW">
                                                            Rwanda
                                                        </option>
                                                        <option value="BL">
                                                            Saint Barthélemy
                                                        </option>
                                                        <option value="SH">
                                                            Saint Helena
                                                        </option>
                                                        <option value="KN">
                                                            Saint Kitts and Nevis
                                                        </option>
                                                        <option value="LC">
                                                            Saint Lucia
                                                        </option>
                                                        <option value="MF">
                                                            Saint Martin
                                                        </option>
                                                        <option value="PM">
                                                            Saint Pierre and Miquelon
                                                        </option>
                                                        <option value="VC">
                                                            Saint Vincent and the Grenadines
                                                        </option>
                                                        <option value="WS">
                                                            Samoa
                                                        </option>
                                                        <option value="SM">
                                                            San Marino
                                                        </option>
                                                        <option value="ST">
                                                            São Tomé and Príncipe
                                                        </option>
                                                        <option value="SA">
                                                            Saudi Arabia
                                                        </option>
                                                        <option value="SN">
                                                            Senegal
                                                        </option>
                                                        <option value="RS">
                                                            Serbia
                                                        </option>
                                                        <option value="SC">
                                                            Seychelles
                                                        </option>
                                                        <option value="SL">
                                                            Sierra Leone
                                                        </option>
                                                        <option value="SG">
                                                            Singapore
                                                        </option>
                                                        <option value="SX">
                                                            Sint Maarten
                                                        </option>
                                                        <option value="SK">
                                                            Slovakia
                                                        </option>
                                                        <option value="SI">
                                                            Slovenia
                                                        </option>
                                                        <option value="SB">
                                                            Solomon Islands
                                                        </option>
                                                        <option value="SO">
                                                            Somalia
                                                        </option>
                                                        <option value="ZA">
                                                            South Africa
                                                        </option>
                                                        <option value="KR">
                                                            South Korea
                                                        </option>
                                                        <option value="ES">
                                                            Spain
                                                        </option>
                                                        <option value="LK">
                                                            Sri Lanka
                                                        </option>
                                                        <option value="SD">
                                                            Sudan
                                                        </option>
                                                        <option value="SR">
                                                            Suriname
                                                        </option>
                                                        <option value="SJ">
                                                            Svalbard and Jan Mayen
                                                        </option>
                                                        <option value="SZ">
                                                            Swaziland
                                                        </option>
                                                        <option value="SE">
                                                            Sweden
                                                        </option>
                                                        <option value="CH">
                                                            Switzerland
                                                        </option>
                                                        <option value="TW">
                                                            Taiwan
                                                        </option>
                                                        <option value="TJ">
                                                            Tajikistan
                                                        </option>
                                                        <option value="TZ">
                                                            Tanzania
                                                        </option>
                                                        <option value="TH">
                                                            Thailand
                                                        </option>
                                                        <option value="TL">
                                                            Timor-Leste
                                                        </option>
                                                        <option value="TG">
                                                            Togo
                                                        </option>
                                                        <option value="TK">
                                                            Tokelau
                                                        </option>
                                                        <option value="TO">
                                                            Tonga
                                                        </option>
                                                        <option value="TT">
                                                            Trinidad and Tobago
                                                        </option>
                                                        <option value="TN">
                                                            Tunisia
                                                        </option>
                                                        <option value="TR">
                                                            Turkey
                                                        </option>
                                                        <option value="TM">
                                                            Turkmenistan
                                                        </option>
                                                        <option value="TC">
                                                            Turks and Caicos Islands
                                                        </option>
                                                        <option value="TV">
                                                            Tuvalu
                                                        </option>
                                                        <option value="UG">
                                                            Uganda
                                                        </option>
                                                        <option value="UA">
                                                            Ukraine
                                                        </option>
                                                        <option value="AE">
                                                            United Arab Emirates
                                                        </option>
                                                        <option value="GB">
                                                            United Kingdom
                                                        </option>
                                                        <option value="US">
                                                            United States
                                                        </option>
                                                        <option value="UY">
                                                            Uruguay
                                                        </option>
                                                        <option value="VI">
                                                            US Virgin Islands
                                                        </option>
                                                        <option value="UZ">
                                                            Uzbekistan
                                                        </option>
                                                        <option value="VU">
                                                            Vanuatu
                                                        </option>
                                                        <option value="VE">
                                                            Venezuela
                                                        </option>
                                                        <option value="VN">
                                                            Vietnam
                                                        </option>
                                                        <option value="WF">
                                                            Wallis and Futuna
                                                        </option>
                                                        <option value="EH">
                                                            Western Sahara
                                                        </option>
                                                        <option value="YE">
                                                            Yemen
                                                        </option>
                                                        <option value="ZM">
                                                            Zambia
                                                        </option>
                                                        <option value="ZW">
                                                            Zimbabwe
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border-bottom pb-2 mb-4">
                                        <h3 className="h4 mb-4">Payment method</h3>
                                        <div className="d-inline-flex align-items-center mb-3">
                                            <strong className="fs-5 me-2">Total:</strong>
                                            <span className="fs-2 fw-semibold text-body-emphasis"><sup>$</sup>{totalPrice}</span>
                                        </div>
                                        <div className="form-check fw-medium pb-3">
                                            <input className="form-check-input" type="radio" name="payment-method" id="radCash" />
                                            <label className="form-check-label" for="radCash">
                                                Pay with cash
                                            </label>
                                        </div>
                                        <div className="form-check fw-medium pb-3">
                                            <input className="form-check-input" type="radio" name="payment-method" id="radPaypal" checked />
                                            <label className="form-check-label d-inline-flex align-items-center" for="radPaypal">
                                                <span className="me-2">Pay via PayPal</span>
                                                <img src="./assets/img/logos/cards.png" srcSet="./assets/img/logos/cards@2x.png 2x" alt="" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <Link to="/booking-completed">
                                            <button type="submit" className="btn btn-primary">
                                                <i className="hicon hicon-bold hicon-pay-on-checkin"></i>
                                                Book and Pay
                                            </button></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <!-- /Booking & Payment --> */}
                    </div>
                </div>
            </section>
            {/* <!-- /Checkout -/-> */}

        </div>)
}

export default Checkout