import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (

        <section data-aos="fade">
            <h1 className="d-none">Moliva</h1>
            <div id="heroCarousel" className="hero hero-carousel carousel slide carousel-fade" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="hero-item carousel-item active">
                        <div className="hero-bg">
                            <img src="assets/img/hero/h2.jpg" srcSet="assets/img/hero/h2@2x.jpg 2x" alt="" />
                        </div>
                        <div className="hero-caption">
                            <div className="container">
                                <div className="row g-0">
                                    <div className="col-12 col-xl-6">
                                        <div className="hero-sub-title">
                                            <span>Explore Moliva</span>
                                        </div>
                                        <h2 className="display-4 hero-title">
                                            Enjoy the beautiful and romantic nature
                                        </h2>
                                        <div className="hero-action">
                                            <Link to="/destinations" className="btn btn-outline-light mnw-180 me-4">
                                                <span>Explore</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                            <a className="btn-video-play btn-video-play-sm media-glightbox" href="assets/media/v1.mp4"><span></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-item carousel-item">
                        <div className="hero-bg">
                            <img src="assets/img/hero/h3.jpg" srcSet="assets/img/hero/h3@2x.jpg 2x" alt="" />
                        </div>
                        <div className="hero-caption">
                            <div className="container">
                                <div className="row g-0">
                                    <div className="col-12 col-xl-6">
                                        <div className="hero-sub-title">
                                            <span>Explore Moliva</span>
                                        </div>
                                        <h2 className="display-4 hero-title">
                                            Explore majestic mountain ranges
                                        </h2>
                                        <div className="hero-action">
                                            <Link to="/tour-packeges" className="btn btn-outline-light mnw-180 me-4">
                                                <span>Explore</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                            <a className="btn-video-play btn-video-play-sm media-glightbox" href="assets/media/v1.mp4"><span></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hero-item carousel-item">
                        <div className="hero-bg">
                            <img src="assets/img/hero/h1.jpg" srcSet="assets/img/hero/h1@2x.jpg 2x" alt="" />
                        </div>
                        <div className="hero-caption">
                            <div className="container">
                                <div className="row g-0">
                                    <div className="col-12 col-xl-6">
                                        <div className="hero-sub-title">
                                            <span>Explore Moliva</span>
                                        </div>
                                        <h2 className="display-4 hero-title">
                                            Experience the unique local culture
                                        </h2>
                                        <div className="hero-action">
                                            <Link to="/tour-packeges" className="btn btn-outline-light mnw-180 me-4">
                                                <span>Explore</span>
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                            <a className="btn-video-play btn-video-play-sm media-glightbox" href="assets/media/v1.mp4"><span></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev d-none d-md-flex" type="button" data-bs-target="#heroCarousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next d-none d-md-flex" type="button" data-bs-target="#heroCarousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                <div className="hero-indicators carousel-indicators justify-content-start">
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
            </div>
            <div className="container">
                <div className="row g-0 justify-content-end">
                    <div className="col-12 col-xl-6">
                        <div className="search-tours">
                            <form className="search-tour-form rounded-top" method="post">
                                <div className="search-tour-input">
                                    <i className="hicon hicon-flights-pin hicon-130"></i>
                                    <input id="txtKeyword" type="text" className="form-control shadow-sm" placeholder="Where are you going?" />
                                </div>
                                <button type="submit" className="btn btn-primary btn-search-tour rounded-0">
                                    <i className="hicon hicon-bold hicon-150 hicon-search-box"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero