import React from 'react'

const Destinationsabout = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div className="container">
                <div className="row g-0">
                    <div className="col-12 col-xl-6 order-1 order-xl-0">
                        <div className="pe-xl-5">
                            <div className="image-info image-info-right mb-5">
                                <div className="image-center rounded">
                                    <img src="./assets/img/about/a4.jpg" srcSet="./assets/img/about/a4@2x.jpg 2x" className="w-100" alt="" />
                                </div>
                                <div className="info-top-right">
                                    <div className="vertical-review rounded">
                                        <div className="review-content">
                                            <h3 className="h1 review-score">4.9</h3>
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-50"></span></span>
                                            <small className="review-total"><strong>2394</strong> reviews</small>
                                            <small className="review-label ">Best choice</small>
                                        </div>
                                        <div className="review-footer">
                                            <small>Tripadvisor</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 order-0 order-xl-1">
                        <div className="pt-4 mb-5 mb-xl-0">
                            <div className="block-title">
                                <small className="sub-title">Beautiful & Romantic</small>
                                <h2 className="h1 title">About Moliva</h2>
                            </div>
                            <p>
                                Moliva is a breathtaking country known for its stunning natural landscapes. From lush green valleys and majestic mountains to pristine beaches and serene lakes, Moliva offers a diverse array of picturesque views that captivate every traveler. The country’s
                                unspoiled beauty provides the perfect backdrop for outdoor adventures and peaceful retreats alike.
                            </p>
                            <p>
                                Beyond its scenic wonders, Moliva is home to incredibly friendly people and a rich cultural heritage. The vibrant culture is a tapestry of unique traditions, colorful festivals, and diverse culinary delights that reflect the country's deep historical
                                roots and artistic expressions. In Moliva, every visit is an opportunity to experience warmth, hospitality, and a true sense of cultural richness.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 g-md-4 mb-0">
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-global"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Continent</small>
                                <h3 className="h5 card-title">Asia</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-poi"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Capital</small>
                                <h3 className="h5 card-title">Noriva city</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-seriously-multilingual"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Language</small>
                                <h3 className="h5 card-title">English</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-more-money"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Currency</small>
                                <h3 className="h5 card-title">Moli</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-light-snow"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Weather</small>
                                <h3 className="h5 card-title">4 Seasons</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-wintry-mix-snow-sleet"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Winter (avg)</small>
                                <h3 className="h5 card-title">41°F/5°C</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-partly-cloudy-day"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Summer (avg)</small>
                                <h3 className="h5 card-title">68°F/20°C</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-3 col-md-6">
                        <div className="mini-card shadow-sm rounded">
                            <span className="card-icon">
                                <i className="hicon hicon-225 hicon-time-clock"></i>
                            </span>
                            <div className="card-content">
                                <small className="card-desc">Time Zone</small>
                                <h3 className="h5 card-title">GMT+2</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default Destinationsabout