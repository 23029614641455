import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import UserProfileCard from '../components/UserProfileCard'
import { useSelector, useDispatch } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { setUserData, removeFromCart } from '../redux/userSlice';

import axios from 'axios';



const Wishlist = ({ loggedIn, adminLoggedIn, setLoggedIn, setAdminLoggedIn }) => {

    const userData = useSelector((state) => state.user.userData);
    const { products } = useSelector((state) => state.product);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    // if (!loading && !error && products.length > 0) {
    //     console.log("products in tour packages ", products);
    // }

    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        // Simple check to see if picture is a URL
        return picture.startsWith('http') ? picture : picture;
    };
    // console.log(userData.cart);
    const cartProducts = products.filter((product) => userData.cart.includes(product._id));

    // console.log(cartProducts);
    const onDeleteProduct = async (productId) => {
        try {
            const response = await axios.post("/user/api/unlikedProduct", { productId });
            console.log(response.data.message);

            // Assuming the API returns the updated userData
            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
            } else {
                // If the API doesn't return userData, manually update the cart
                dispatch(removeFromCart(productId));
            }
        } catch (error) {
            console.error("Error disliking the product:", error.response ? error.response.data.message : error.message);
        }
    };




    return (

        <main>
            <div class="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">

                {/* <!-- Title --> */}
                <section class="container">
                    <div class="d-lg-flex align-items-lg-end pb-4">
                        <div class="block-title me-auto">
                            <small class="sub-title">My Account</small>
                            <h1 class="display-5 title">Wishlist</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb mt-3">
                                <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li class="breadcrumb-item"><Link to="#">Pages</Link></li>
                                <li class="breadcrumb-item active" aria-current="page">Wishlist</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Wishlist --> */}
                <section class="container">
                    <div class="row g-0 g-xl-8">
                        <div class="col-12 col-xl-9">
                            {/* <!-- Tour list --> */}
                            <div class="pe-xl-5">
                                <div class="row">

                                    {
                                        cartProducts.length > 0 && cartProducts.map((product) => (
                                            <div class="col-12 col-md-6">
                                                {/* <!-- Tour --> */}
                                                <div class="card border-0 shadow-sm hover-effect mb-4">
                                                    <Link to="/single-tour" state={{ product, products }}>
                                                        <figure class="image-hover image-hover-overlay rounded-top mb-0">
                                                            <img src={getImageSrc(product.image[0].imageAdress)} alt={product.name} />
                                                            <i class="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                                        </figure>
                                                    </Link>
                                                    <div class="card-body position-relative">
                                                        <div class="mb-4">
                                                            <h2 class="h5">
                                                                <Link to="/single-tour" state={{ product, products }} class="link-dark link-hover">{product.name}</Link>
                                                            </h2>
                                                            <div class="fsm-1">
                                                                <span class="me-2"><i class="hicon hicon-menu-calendar"></i>  {product.iteracy.curriculum.length} days</span>
                                                                <span class="me-2"><i class="hicon hicon-flights-pin"></i> {product.about.highlight.length} Destinations</span>
                                                            </div>
                                                        </div>
                                                        <div class="inline-review mb-4">
                                                            <span class="review-score">4.9</span>
                                                            <span class="star-rate-view star-rate-size-sm me-2"><span class="star-value rate-50"></span></span>
                                                            <small class="review-total"><span>(231 reviews)</span></small>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <div class="me-auto">
                                                                <strong class="fs-4 fw-semibold"><sup>$</sup>{product.price}</strong>
                                                                <span class="fsm-4 text-secondary"><sup>$</sup><del class="">500.00</del></span>
                                                            </div>
                                                            <Link to="/single-tour" state={{ product, products }} class="circle-icon circle-icon-link">
                                                                <i class="hicon hicon-flights-one-ways"></i>
                                                            </Link>
                                                        </div>
                                                        <div class="position-absolute top-0 end-0 translate-middle-y me-4">
                                                            <button onClick={() => onDeleteProduct(product._id)} type="button" class="circle-icon delete-icon shadow-sm">
                                                                <i class="hicon hicon-x-icon hicon-70"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- /Tour --> */}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {/* <!-- Tour list --> */}
                        </div>
                        <div class="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div class="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />

                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Wishlist --> */}

            </div>
        </main>)
}

export default Wishlist