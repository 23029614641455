import React from 'react'
import { Link } from 'react-router-dom'

const Destinationsdestination = () => {
    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient">
            <div className="container">
                <div className="d-xl-flex align-items-xl-center pb-4" data-aos="fade">
                    <div className="block-title me-auto">
                        <small className="sub-title">Explore Moliva</small>
                        <h2 className="h1 title">Destinations in Moliva</h2>
                    </div>
                    <div className="extra-info mt-3">
                        <strong className="text-body">53</strong>
                        <span className="text-secondary">Attractive destinations</span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-4 col-md-6" data-aos="fade">
                        <Link to="/tour-packeges" className="bottom-overlay rounded hover-effect mb-4">
                            <figure className="image-hover image-hover-overlay mb-0">
                                <img src="./assets/img/destinations/d1.jpg" srcset="./assets/img/destinations/d1@2x.jpg 2x" alt="" />
                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                            </figure>
                            <div className="bottom-overlay-content">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column me-auto">
                                        <h3 className="mb-0">Noriva</h3>
                                        <span>162 tours</span>
                                    </div>
                                    <span className="circle-icon circle-icon-link">
                                        <i className="hicon hicon-flights-pin"></i>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6" data-aos="fade">
                        <Link to="/tour-packeges" className="bottom-overlay rounded hover-effect mb-4">
                            <figure className="image-hover image-hover-overlay mb-0">
                                <img src="./assets/img/destinations/d2.jpg" srcset="./assets/img/destinations/d2@2x.jpg 2x" alt="" />
                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                            </figure>
                            <div className="bottom-overlay-content">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column me-auto">
                                        <h3 className="mb-0">Kardal</h3>
                                        <span>127 tours</span>
                                    </div>
                                    <span className="circle-icon circle-icon-link">
                                        <i className="hicon hicon-flights-pin"></i>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6" data-aos="fade">
                        <Link to="/tour-packeges" className="bottom-overlay rounded hover-effect mb-4">
                            <figure className="image-hover image-hover-overlay mb-0">
                                <img src="./assets/img/destinations/d3.jpg" srcset="./assets/img/destinations/d3@2x.jpg 2x" alt="" />
                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                            </figure>
                            <div className="bottom-overlay-content">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column me-auto">
                                        <h3 className="mb-0">Leront</h3>
                                        <span>155 tours</span>
                                    </div>
                                    <span className="circle-icon circle-icon-link">
                                        <i className="hicon hicon-flights-pin"></i>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6" data-aos="fade">
                        <Link to="/tour-packeges" className="bottom-overlay rounded hover-effect mb-4">
                            <figure className="image-hover image-hover-overlay mb-0">
                                <img src="./assets/img/destinations/d4.jpg" srcset="./assets/img/destinations/d4@2x.jpg 2x" alt="" />
                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                            </figure>
                            <div className="bottom-overlay-content">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column me-auto">
                                        <h3 className="mb-0">Fruska</h3>
                                        <span>210 tours</span>
                                    </div>
                                    <span className="circle-icon circle-icon-link">
                                        <i className="hicon hicon-flights-pin"></i>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6" data-aos="fade">
                        <Link to="/tour-packeges" className="bottom-overlay rounded hover-effect mb-4">
                            <figure className="image-hover image-hover-overlay mb-0">
                                <img src="./assets/img/destinations/d6.jpg" srcset="./assets/img/destinations/d6@2x.jpg 2x" alt="" />
                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                            </figure>
                            <div className="bottom-overlay-content">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column me-auto">
                                        <h3 className="mb-0">Zolmir</h3>
                                        <span>176 tours</span>
                                    </div>
                                    <span className="circle-icon circle-icon-link">
                                        <i className="hicon hicon-flights-pin"></i>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-xl-4 col-md-6" data-aos="fade">
                        <Link to="/tour-packeges" className="bottom-overlay rounded hover-effect mb-4">
                            <figure className="image-hover image-hover-overlay mb-0">
                                <img src="./assets/img/destinations/d5.jpg" srcset="./assets/img/destinations/d5@2x.jpg 2x" alt="" />
                                <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                            </figure>
                            <div className="bottom-overlay-content">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column me-auto">
                                        <h3 className="mb-0">Sitafo</h3>
                                        <span>321 tours</span>
                                    </div>
                                    <span className="circle-icon circle-icon-link">
                                        <i className="hicon hicon-flights-pin"></i>
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <nav aria-label="Page navigation" className="pt-3 text-center" data-aos="fade">
                    <ul className="pagination pagination-circle d-inline-flex mb-0">
                        <li className="page-item">
                            <Link className="page-link" to="#">
                                <i className="hicon hicon-edge-arrow-left"></i>
                            </Link>
                        </li>
                        <li className="page-item"><Link className="page-link" to="#">1</Link></li>
                        <li className="page-item">
                            <Link className="page-link" to="#">
                                <i className="hicon hicon-edge-arrow-right"></i>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    )
}

export default Destinationsdestination