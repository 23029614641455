import React from 'react'
import Hero from '../components/Hero'
import Featured from '../components/Featured'
import About from '../components/About'
import Topdestination from '../components/Topdestination'
import Tourtypes from '../components/Tourtypes'
import Whyus from '../components/Whyus'
import Tours from '../components/Tours'
import Videos from '../components/Videos'
import Categories from '../components/Categories'
import Booking from '../components/Booking'
import Testimonials from '../components/Testimonials'
import Travelinsight from '../components/Travelinsight'

const Home = () => {
    return (
        <div>
            <main>
                <Hero />
                <Featured />
                <About />
                <Topdestination />
                <Tourtypes />
                <Whyus />
                <Tours />
                <Videos />
                <Categories />
                <Booking />
                <Testimonials />
                <Travelinsight />
            </main>
        </div>
    )
}

export default Home