import React, { useEffect, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchProducts } from '../redux/productSlice'
import { removeFromOrders, setUserData } from '../redux/userSlice';
import axios from 'axios';

const Shoppingcart = () => {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const { products, loading } = useSelector((state) => state.product);
    const dataLoadedRef = useRef(false);

    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
        if (!loading && products.length > 0 && !dataLoadedRef.current) {
            // console.log("All data loaded:", products);
            dataLoadedRef.current = true;
        }
    }, [loading, products])

    const userProducts = useMemo(() => {
        if (!userData || !userData.orders || !products) return [];
        const orderProductIds = userData.orders.map(order => order.product);
        return products.filter(product => orderProductIds.includes(product._id));
    }, [userData, products]);
    // console.log("userData:", userProducts);

    // console.log("userProducts:", userProducts);
    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        return picture.startsWith('http') ? picture : picture;
    };
    const totalPrice = useMemo(() => {
        return userProducts.reduce((acc, product) => acc + product.price, 0);
    }, [userProducts]);

    const onRemoveProduct = async (productId) => {
        // console.log('Attempting to delete product:', productId); // Debug log
        try {
            const response = await axios.post("/user/api/removeProduct", { productId });
            console.log(response.data.message);

            // Check if userData is returned and update the state
            if (response.data.userData) {
                dispatch(setUserData({ ...response.data.userData, isLoggedIn: true }));
            } else {
                // If userData is not returned, update cart
                dispatch(removeFromOrders(productId));
            }
        } catch (error) {
            // Enhanced error handling
            const errorMessage = error.response
                ? error.response.data.message || error.message
                : error.message;
            console.error("Error removing the product:", errorMessage);
            // alert("Error removing the product. Please try again."); // Optional user feedback
        }
    };

    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">

                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">Booking</small>
                            <h1 className="display-5 title">Shopping cart</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Shopping cart</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="booking-process fw-normal mb-5" data-booking-step="1">
                        <span className="circle-icon circle-icon-sm booking-step step-1">1</span>
                        <span className="circle-icon circle-icon-sm booking-step step-2">2</span>
                        <span className="circle-icon circle-icon-sm booking-step step-3">3</span>
                    </div>
                </section>

                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            <div className="pe-xl-5">
                                {
                                    userProducts.map((product) => {
                                        const productID = product._id;
                                        const orderData = userData.orders.filter(order => order.product === productID); // Accessing data from userData
                                        const date = new Date(orderData[0].departureDate);
                                        const options = { year: 'numeric', month: 'short', day: '2-digit' };
                                        const formattedDate = date.toLocaleDateString('en-US', options);
                                        return (
                                            <div key={productID} className="card border-0 shadow-sm mb-4">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 col-xl-3 col-md-4">
                                                            <Link to={`/single-tour/${productID}`}>
                                                                <figure className="image-hover image-hover-overlay rounded mb-0">
                                                                    <img
                                                                        src={getImageSrc(product.image[0].imageAddress)}
                                                                        alt={product.name}
                                                                        className="img-fluid"
                                                                    />
                                                                    <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                                                </figure>
                                                            </Link>
                                                        </div>
                                                        <div className="col-12 col-xl-9 col-md-8">
                                                            <div className="d-md-flex pt-4 pt-md-0">
                                                                <div className="pe-4 flex-grow-1">
                                                                    <h2 className="h5 mb-2">
                                                                        <Link to={`/single-tour/${productID}`} className="link-dark link-hover">
                                                                            {product.name}
                                                                        </Link>
                                                                    </h2>
                                                                    <div className="mb-2">
                                                                        <small className="me-2">
                                                                            <i className="hicon hicon-menu-calendar"></i> {product.iteracy.curriculum.length} days
                                                                        </small>
                                                                        <small className="me-2">
                                                                            <i className="hicon hicon-flights-pin"></i> {product.about.highlight.length} Destinations
                                                                        </small>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <small className="mb-2">
                                                                            Tour date: <strong>{formattedDate}</strong>
                                                                        </small>
                                                                        <small className="mb-2">
                                                                            Guests: <strong>{orderData[0].adults} Adults - {orderData[0].children} Children</strong>
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                                <div className="text-md-end">
                                                                    <div className="d-flex flex-md-column align-items-end mb-2">
                                                                        <strong className="fs-4 fw-semibold">
                                                                            <sup>$</sup>{product.price}
                                                                        </strong>
                                                                        <small className="text-secondary">
                                                                            <sup>$</sup><del>{product.price + 180}</del>
                                                                        </small>
                                                                    </div>
                                                                    <button type="button" className="btn btn-link link-danger p-0" onClick={() => onRemoveProduct(product._id)}>
                                                                        <i className="hicon hicon-x-icon hicon-70"></i>
                                                                        <span>Remove</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>

                        </div>
                        <div className="col-12 col-xl-3">
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 z-0">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <strong className="fs-5">Total ({userProducts.length}):</strong>
                                            <span className="fs-2 fw-semibold text-body-emphasis"><sup>$</sup>{totalPrice}</span>
                                        </div>
                                        <p className="text-secondary small text-end">
                                            <em>* All taxes and fees included</em>
                                        </p>
                                        <Link to="/checkout">
                                            <div className="mt-4">
                                                <button type="button" className="btn btn-primary w-100">
                                                    <i className="hicon hicon-installment-payment"></i>
                                                    <span>Checkout</span>
                                                </button>
                                            </div></Link>
                                    </div>
                                    <div className="border-top pt-4">
                                        <div className="row">
                                            <div className="col-12 col-xl-12 col-md-6">
                                                <div className="d-flex">
                                                    <span className="me-1 text-success"><i className="hicon hicon-bold hicon-check"></i></span>
                                                    <dl className="mb-0">
                                                        <dt className="fw-medium">Pay nothing today</dt>
                                                        <dd className="text-secondary small">Book now and pay on Jun 30</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-12 col-md-6">
                                                <div className="d-flex">
                                                    <span className="me-1 text-success"><i className="hicon hicon-bold hicon-check"></i></span>
                                                    <dl className="mb-0">
                                                        <dt className="fw-medium">Free cancellation</dt>
                                                        <dd className="text-secondary small">Until 7:00 AM on Jun 30</dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </main>
    )
}

export default Shoppingcart