import React, { useEffect } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';



const Testimonials = () => {


    useEffect(() => {
        const splide = new Splide('#testimonial-slider', {
            perPage: 3,
            gap: '1.5rem',
            padding: 0,
            speed: 1000, // Improved readability by changing 1e3 to 1000
            type: 'loop',
            focus: 0,
            breakpoints: {
                1200: {
                    perPage: 1,
                    padding: { left: 0, right: '280px' },
                    arrows: false,
                },
                992: {
                    perPage: 1,
                    padding: { left: 0, right: '250px' },
                    arrows: false,
                },
                576: {
                    perPage: 1,
                    gap: '0.75rem',
                    padding: 0,
                    arrows: false,
                },
            },
        });

        splide.on('mounted', () => {
            const destinationSlider = document.getElementById('testimonial-slider');


            if (destinationSlider) destinationSlider.style.visibility = 'visible';


        });

        splide.mount();
    }, []);
    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div className="container">
                <div className="testimonials-slider splide splide__pagination__start w-100 mb-5" id='testimonial-slider'>
                    <div className="d-xl-flex align-items-xl-center mb-3">
                        <div className="block-title me-auto">
                            <small className="sub-title">Genuine Reviews</small>
                            <h2 className="h1 title">Tourists talk about us</h2>
                        </div>
                        <div className="d-lg-flex align-items-lg mt-3">
                            <div className="d-md-flex align-items-md-center me-md-4">
                                <div className="extra-info me-4">
                                    <strong>+95K</strong>
                                    <span>Tour bookings</span>
                                </div>
                                <div className="extra-info me-4">
                                    <strong>4.9</strong>
                                    <span className="fw-medium text-secondary">
                                        <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-50"></span></span>
                                        <span>(+85K reviews)</span>
                                    </span>
                                </div>
                            </div>
                            <div className="splide__arrows splide__arrows__right">
                                <button
                                    className="splide__arrow splide__arrow--prev me-2"
                                    style={{ backgroundColor: "#0a3e59", height: "40px", width: "40px" }}
                                >
                                    <i className="hicon hicon-edge-arrow-left"></i>
                                </button>
                                <button
                                    className="splide__arrow splide__arrow--next"
                                    style={{ backgroundColor: "#0a3e59", height: "40px", width: "40px" }}
                                >
                                    <i className="hicon hicon-edge-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list">
                            <li className="splide__slide">
                                <div className="testimonial shadow-sm rounded mb-1 hover-effect">
                                    <span className="testimonial-icon">
                                        <i className="hicon hicon-message-right"></i>
                                    </span>
                                    <div className="testimonial-client">
                                        <img src="assets/img/avatars/a1.jpg" srcSet="assets/img/avatars/a1@2x.jpg 2x" alt="" />
                                        <div className="name">
                                            <h3 className="h5 mb-0">John Doe</h3>
                                            <span>Venice, Italy</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-review">
                                            Our trip to Moliva was amazing! Moliva Travel Agency organized everything perfectly,
                                            from the hotels to the sightseeing spots. I was very impressed and will definitely return!
                                        </blockquote>
                                        <div className="testimonial-star">
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                            <span className="testimonial-date rounded-1">Jun 25 24</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="testimonial shadow-sm rounded mb-1 hover-effect">
                                    <span className="testimonial-icon">
                                        <i className="hicon hicon-message-right"></i>
                                    </span>
                                    <div className="testimonial-client">
                                        <img src="assets/img/avatars/a2.jpg" srcSet="assets/img/avatars/a2@2x.jpg 2x" alt="" />
                                        <div>
                                            <h3 className="h5 mb-0">Emily Smith</h3>
                                            <span>Chicago, USA</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-review">
                                            We had an unforgettable vacation in Moliva thanks to the excellent service of Moliva Travel Agency.
                                            The itinerary was well-arranged, and the support team was very helpful. Best trip ever!
                                        </blockquote>
                                        <div className="testimonial-star">
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                            <span className="testimonial-date rounded-1">Jun 28 24</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="testimonial shadow-sm rounded mb-1 hover-effect">
                                    <span className="testimonial-icon">
                                        <i className="hicon hicon-message-right"></i>
                                    </span>
                                    <div className="testimonial-client">
                                        <img src="assets/img/avatars/a3.jpg" srcSet="assets/img/avatars/a3@2x.jpg 2x" alt="" />
                                        <div>
                                            <h3 className="h5 mb-0">Alex Mark</h3>
                                            <span>Texas, USA</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-review">
                                            Moliva is a perfect destination, and Moliva Travel Agency made our trip flawless.
                                            From booking to sightseeing activities, everything was wonderful. I am very satisfied!
                                        </blockquote>
                                        <div className="testimonial-star">
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                            <span className="testimonial-date rounded-1">Jun 28 24</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="testimonial shadow-sm rounded mb-1 hover-effect">
                                    <span className="testimonial-icon">
                                        <i className="hicon hicon-message-right"></i>
                                    </span>
                                    <div className="testimonial-client">
                                        <img src="assets/img/avatars/a4.jpg" srcSet="assets/img/avatars/a4@2x.jpg 2x" alt="" />
                                        <div>
                                            <h3 className="h5 mb-0">Ariol Deep</h3>
                                            <span>Lisbon, Portugal</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-review">
                                            Moliva Travel Agency provided an excellent vacation in Moliva. Tours were well-organized,
                                            the schedule was great, and customer service was top-notch. Highly recommended!
                                        </blockquote>
                                        <div className="testimonial-star">
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                            <span className="testimonial-date rounded-1">Jun 28 24</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="testimonial shadow-sm rounded mb-1 hover-effect">
                                    <span className="testimonial-icon">
                                        <i className="hicon hicon-message-right"></i>
                                    </span>
                                    <div className="testimonial-client">
                                        <img src="assets/img/avatars/a3.jpg" srcSet="assets/img/avatars/a3@2x.jpg 2x" alt="" />
                                        <div>
                                            <h3 className="h5 mb-0">Alex Mark</h3>
                                            <span>Texas, USA</span>
                                        </div>
                                    </div>
                                    <div className="testimonial-content">
                                        <blockquote className="testimonial-review">
                                            Rationi bus incor rupte vim te. Choro nominati cum choro epicuri deleniti eu asse ntior te sit,
                                            mea tale movet docendi quae stio vis ut. An vitae altera fierent nam vis.
                                        </blockquote>
                                        <div className="testimonial-star">
                                            <span className="star-rate-view star-rate-size-sm"><span className="star-value rate-45"></span></span>
                                            <span className="testimonial-date rounded-1">Jun 28 24</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials