import React from 'react'
import { Link } from 'react-router-dom'

const Destinationtitle = () => {
    return (
        <section className="hero" data-aos="fade">
            <div className="hero-bg">
                <img src="./assets/img/destinations/d7.jpg" srcset="./assets/img/destinations/d7@2x.jpg 2x" alt="" />
            </div>
            <div className="container">
                <div className=" p-top-180 p-bottom-180">
                    <span className="hero-sub-title">Moliva tours</span>
                    <h1 className="display-4 hero-title mb-4">
                        Destinations
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Destinations</li>
                        </ol>
                    </nav>
                </div>
                <div className="row g-0 justify-content-end">
                    <div className="col-12 col-xl-6">
                        <div className="search-tours">
                            <form className="search-tour-form rounded-top" method="post">
                                <div className="search-tour-input">
                                    <i className="hicon hicon-flights-pin hicon-130"></i>
                                    <input id="txtKeyword" type="text" className="form-control shadow-sm" placeholder="Where are you going?" />
                                </div>
                                <button type="submit" className="btn btn-primary btn-search-tour rounded-0">
                                    <i className="hicon hicon-bold hicon-150 hicon-search-box"></i>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Destinationtitle