import React from 'react'
import { Link } from 'react-router-dom'

const Aboutustitle = () => {
    return (
        <section class="hero" data-aos="fade">
            <div class="hero-bg">
                <img src="./assets/img/about/a6.jpg" srcset="./assets/img/about/a6@2x.jpg 2x" alt="" />
            </div>
            <div class="container">
                <div class=" p-top-200 p-bottom-200">
                    <span class="hero-sub-title">Get to Know Us</span>
                    <h1 class="display-4 hero-title mb-4">
                        About Us
                    </h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li class="breadcrumb-item"><Link to="#">Pages</Link></li>
                            <li class="breadcrumb-item active" aria-current="page">About us</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>)
}

export default Aboutustitle