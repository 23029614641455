import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { setUserData, addToCart, removeFromCart } from '../redux/userSlice';
import axios from 'axios';

const Tours = () => {

    const [errorMessage, setErrorMessage] = useState(""); // State to store error message
    const [successMessage, setSuccessMessage] = useState("");


    const loggedIn = useSelector((state) => state.user.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // console.log("User Data: ", userData);
    // console.log("Is Logged In: ", loggedIn);

    const { products } = useSelector((state) => state.product);
    const userData = useSelector((state) => state.user.userData);

    useEffect(() => {
        dispatch(fetchProducts());
        if (loggedIn) {
            axios.get("/user/api/getUserData")
                .then(response => {
                    dispatch(setUserData(response.data.userData));
                })
                .catch(error => {
                    console.error("Error fetching user data:", error.message);
                });
        }
    }, [dispatch, loggedIn]);

    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        return picture.startsWith('http') ? picture : picture;
    };

    const onLikeProduct = async (productId) => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }

        try {
            const response = await axios.post("/user/api/likedProduct", { productId });
            console.log(response.data.message);

            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            } else {
                dispatch(addToCart(productId));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            }
        } catch (error) {
            // console.error("Error liking the product:", error.response ? error.response.data.message : error.message);
            if (error.response) {
                if (error.response.status === 409) {
                    // Handle the case where the product is already liked
                    try {
                        const response = await axios.post("/user/api/unlikedProduct", { productId });
                        console.log(response.data.message);

                        // Assuming the API returns the updated userData
                        if (response.data.userData) {
                            dispatch(setUserData(response.data.userData));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        } else {
                            // If the API doesn't return userData, manually update the cart
                            dispatch(removeFromCart(productId));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        }
                    } catch (error) {
                        console.error("Error disliking the product:", error.response ? error.response.data.message : error.message);
                    }
                } else {
                    // Handle other errors
                    setErrorMessage(error.response.data.message);
                    setSuccessMessage("");
                }
            } else {
                setErrorMessage(error.message); // Handle network or other errors
                setSuccessMessage("");
            }

            console.error("Error liking the product:", errorMessage);
        }
    };

    useEffect(() => {
        new Splide('#other-tour-slider', {
            type: 'loop',
            perPage: 4,
            perMove: 1,
            pagination: true,
            arrows: true,
            gap: '1rem',
            breakpoints: {
                1200: {
                    perPage: 2,
                },
                768: {
                    perPage: 1,
                },
            },
        }).mount();

        // Make the slider visible after initialization
        const sliderElement = document.querySelector('.other-tour-slider');
        if (sliderElement) {
            sliderElement.style.visibility = 'visible';
        }
    }, []);
    return (
        <section class="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div class="container">
                <div id='other-tour-slider' class="other-tour-slider splide splide__pagination__start w-100">
                    {/* <!-- Title --> */}
                    <div class="d-xl-flex align-items-xl-end pb-3">
                        <div class="block-title me-auto">
                            <small class="sub-title">Many tourists choose
                            </small>
                            <h2 class="h1 title">Top Moliva Tours
                            </h2>
                        </div>
                        <div class="splide__arrows splide__arrows__right">
                            <button class="splide__arrow splide__arrow--prev me-2" style={{ backgroundColor: "#0a3e59", height: "40px", width: "40px" }} >
                                <i class="hicon hicon-edge-arrow-left"></i>
                            </button>
                            <button class="splide__arrow splide__arrow--next" style={{ backgroundColor: "#0a3e59", height: "40px", width: "40px" }} >
                                <i class="hicon hicon-edge-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    {/* <!-- /Title --> */}
                    {/* <!-- Tours list --> */}
                    <div class="splide__track pt-2 pb-2">
                        {successMessage && (
                            <div className="alert alert-success">
                                {successMessage}
                            </div>
                        )}
                        {errorMessage && (
                            <div className="alert alert-danger">
                                {errorMessage}
                            </div>
                        )}
                        <ul class="splide__list">
                            {
                                products.map((product) => (
                                    <li class="splide__slide">
                                        {/* <!-- Tour --> */}
                                        <div class="card border-0 shadow-sm hover-effect">
                                            <Link to="/single-tour" state={{ product, products }}>
                                                <figure class="image-hover image-hover-overlay rounded-top mb-0">
                                                    <img src={getImageSrc(product.image[0].imageAdress)} alt={product.name} />
                                                    <i class="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                                </figure>
                                            </Link>
                                            <div class="card-body position-relative">
                                                <div class="mb-4">
                                                    <h3 class="h5">
                                                        <Link to="/single-tour" state={{ product, products }} class="link-dark link-hover">{product.name}</Link>
                                                    </h3>
                                                    <div class="fsm-1">
                                                        <span class="me-3"><i class="hicon hicon-menu-calendar"></i> {product.iteracy.curriculum.length} days</span>
                                                        <span class="me-3"><i class="hicon hicon-flights-pin"></i> {product.about.highlight.length} Destinations</span>
                                                    </div>
                                                </div>
                                                <div class="inline-review mb-4">
                                                    <span class="review-score">4.9</span>
                                                    <span class="star-rate-view star-rate-size-sm me-2"><span class="star-value rate-50"></span></span>
                                                    <small class="review-total"><span>(231 reviews)</span></small>
                                                </div>
                                                <div class="d-flex align-items-center">
                                                    <div class="me-auto">
                                                        <strong class="fs-4 fw-semibold"><sup>$</sup>{product.price}</strong>
                                                        <span class="fsm-4 text-secondary"><sup>$</sup><del class="">500.00</del></span>
                                                    </div>
                                                    <Link to="/single-tour" state={{ product, products }} class="circle-icon circle-icon-link">
                                                        <i class="hicon hicon-flights-one-ways"></i>
                                                    </Link>
                                                </div>
                                                <div class="position-absolute top-0 end-0 translate-middle-y me-4">
                                                    <button type="button" onClick={() => onLikeProduct(product._id)} class="circle-icon like-icon shadow-sm" style={{ color: userData?.cart?.includes(product._id) ? '#0077B3' : 'grey' }}
                                                    >
                                                        <i class="hicon hicon-favorite-filled"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- /Tour --> */}
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    {/* <!-- /Tour List --> */}
                </div>
                <p class="fw-normal text-secondary text-md-end mb-0 pt-4 mt-5 mt-md-0" data-aos="fade">
                    <Link to="/tour-packeges" class="fw-medium">
                        <span>View all tours</span>
                        <i class="hicon hicon-flights-one-ways"></i>
                    </Link>
                </p>
            </div>
        </section>)
}



export default Tours