import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserData } from '../redux/userSlice'; // assuming this is your slice
import { useGoogleLogin } from '@react-oauth/google';




function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const googleAuth = async (code) => {
        try {
            const result = await axios.get(`/user/google?code=${code}`, { withCredentials: true });

            if (result.status === 200) {
                navigate('/');
                const userdata = result.data.user

                console.log(userdata);

                dispatch(setUserData({
                    ...userdata,
                    isLoggedIn: true,
                }));
            }
            return result.data;
        } catch (error) {
            console.error("Error while requesting Google auth:", error.message);
            throw new Error("Error while requesting Google auth: " + error.message);
        }
    };


    const responsegoogle = async (authResult) => {
        try {
            if (authResult['code']) {
                await googleAuth(authResult['code']);

                // Redirect to the shop page on successful login

                // Assuming response.data contains the user info
                // console.log(userdata);

            }
        } catch (error) {
            console.log("Error while requesting Google login: ", error);
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: responsegoogle,
        onError: responsegoogle,
        flow: 'auth-code'
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/user/api/login', { email, password });
            if (response.status === 200) {
                // Redirect to the shop page on successful login
                navigate('/');
                const recieveresponse = await axios.get("/user/api/users")
                const userdata = recieveresponse.data
                // console.log("userdata", userdata);
                dispatch(setUserData({
                    ...userdata,
                    isLoggedIn: true, // Ensure this flag is part of the dispatched data
                })); // Assuming response.data contains the user info
                // console.log(userdata);
            }
        } catch (err) {
            console.error("Login Error:", err.response ? err.response.data : err.message);
            if (err.response && err.response.status === 401) {
                setError('User does not exist.');
            } else if (err.response && err.response.status === 402) {
                setError('Email or password is incorrect.');
            } else {
                setError('An error occurred during login. Please try again.');
            }
        }

    };

    return (
        <main>
            <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 col-lg-8 col-md-9">
                            <div className="card border-0 shadow-sm p-xl-2">
                                <div className="card-body">
                                    <div className="border-bottom mb-4">
                                        <h1 className="h2 text-body-emphasis">Login</h1>
                                    </div>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <form onSubmit={handleSubmit}>
                                        {/* Login with Facebook/Google */}
                                        <div className="border-bottom pb-4 mb-4">
                                            <div className="row g-3">

                                                <div className="col-12">
                                                    <button type="button" onClick={googleLogin} className="btn btn-google w-100">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 534 544" width="20" height="20">
                                                            <path fill="#4285f4" d="M534 278c0-18-2-37-5-55H272v105h147c-6 34-26 64-54 83v68h87c52-48 82-118 82-201z" />
                                                            <path fill="#34a853" d="M272 544c74 0 135-24 181-65l-88-68c-25 16-56 26-93 26-71 0-131-48-153-113H29v70c46 92 140 150 243 150z" />
                                                            <path fill="#fbbc04" d="M119 324c-11-33-11-70 0-104v-70H29c-39 77-39 168 0 244l90-70z" />
                                                            <path fill="#ea4335" d="M272 108c39-1 76 14 105 41l77-78a272 272 0 0 0-425 79l90 70c22-64 82-112 153-112z" />
                                                        </svg>
                                                        <span className="ms-2">Google</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Login with Email */}
                                        <div className="border-bottom pb-4">
                                            <div className="mb-4">
                                                <label htmlFor="txtEmail" className="form-label">Email<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    type="text"
                                                    id="txtEmail"
                                                    name="username"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label htmlFor="txtPassword" className="form-label">Password<span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control shadow-sm"
                                                    id="txtPassword"
                                                    name="password"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="mb-4">
                                                        <div className="form-check">
                                                            <input className="form-check-input shadow-sm" type="checkbox" id="chkRemember" />
                                                            <label className="form-check-label" htmlFor="chkRemember"><span className="text-body">Remember me</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="mb-4 text-end">
                                                        <Link to="/forgot-password">Forgot password?</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <button className="btn btn-primary w-100" type="submit" id="btnSubmit">
                                                    <i className="hicon hicon-aps-lock"></i>
                                                    <span>Login</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <span>Don't have an account? <Link to="/register">Register</Link></span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Login;
