import React from 'react'
import Destinationtitle from '../components/Destinationtitle'
import Destinationsdestination from '../components/Destinationsdestination'
import Destinationsabout from '../components/Destinationsabout'
import Travelinsight from '../components/Travelinsight'

const Destinations = () => {
    return (
        <div>
            <main>
                <Destinationtitle />
                <Destinationsdestination />
                <Destinationsabout />
                <Travelinsight />
            </main>
        </div>
    )
}

export default Destinations