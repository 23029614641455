import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className="footer p-top-90 p-bottom-90" data-aos="fade">

            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-3 col-md-6">
                            <div className="footer-widget">
                                <Link to="/" className="brand-img">
                                    <img className="me-4" src="assets/img/logos/footer-logo.png" srcSet="assets/img/logos/footer-logo@2x.png 2x" alt="" />
                                </Link>
                                <p className="brand-desc">
                                    <em>
                                        Moliva Travel Agency offers unique and memorable tours, providing rich experiences in the beautiful country of Moliva.
                                    </em>
                                    <Link to="/about-us">[+]</Link>
                                </p>
                                <ul className="social-list">
                                    <li className="social-item">
                                        <Link to="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" strokeWidth="1.75" stroke="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="none" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
                                                <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.75" stroke="none" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 5m0 4a4 4 0 0 1 4 -4h10a4 4 0 0 1 4 4v6a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4z"></path>
                                                <path d="M10 9l5 3l-5 3z"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.75" stroke="none" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M8 20l4 -9"></path>
                                                <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                    <li className="social-item">
                                        <Link to="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.75" stroke="none" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M6.5 13.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                                                <path d="M17.5 13.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0"></path>
                                                <path d="M17.5 9a4.5 4.5 0 1 0 3.5 1.671l1 -1.671h-4.5z"></path>
                                                <path d="M6.5 9a4.5 4.5 0 1 1 -3.5 1.671l-1 -1.671h4.5z"></path>
                                                <path d="M10.5 15.5l1.5 2l1.5 -2"></path>
                                                <path d="M9 6.75c2 -.667 4 -.667 6 0"></path>
                                            </svg>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            <div className="footer-widget">
                                <h2 className="h4 pb-3">Contact Info</h2>
                                <div className="contact-info">
                                    <p>
                                        <span>No 234, Placer Loquen Marsei Niriva, Moliva.</span>
                                    </p>
                                    <p>
                                        <span>+33 321-654-987 (Ext: 123).</span>
                                    </p>
                                    <p>
                                        <Link to="#">Booking@example.com</Link>
                                    </p>
                                    <p>
                                        <Link to="#">www.example.com</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            <div className="footer-widget">
                                <h2 className="h4 pb-3">Moliva Travel</h2>
                                <ul className="footer-link">
                                    <li className="link-item">
                                        <Link to="/about-us">About us</Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="/destinations">Destinations</Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="/tour-packeges">Moliva Tours</Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="./post-list.html">Travel insight</Link>
                                    </li>
                                    <li className="link-item">
                                        <Link to="./contact.html">Contact us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            <div className="footer-widget">
                                <h2 className="h4 pb-3">Get the app</h2>
                                <div className="mb-5 pt-3">
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <Link to="#">
                                                <img src="assets/img/icons/i1.svg" className="w-100" alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-6">
                                            <Link to="#">
                                                <img src="assets/img/icons/i2.svg" className="w-100" alt="" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-local">
                                    <Link data-bs-toggle="modal" href="#mdlLanguage" className="me-4">
                                        <img src="assets/img/flags/en.svg" height="14" alt="" className="me-2" />
                                        <span className="me-1">English</span>
                                        <i className="hicon hicon-thin-arrow-down fsm-6"></i>
                                    </Link>
                                    <Link data-bs-toggle="modal" href="#mdlCurrency">
                                        <span className="me-1">USD (US Dollar)</span>
                                        <i className="hicon hicon-thin-arrow-down fsm-6"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p className="mb-lg-0">© 2024 Moliva Travel Agency. All rights reserved.</p>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="text-start text-md-end">
                                <ul className="list-inline mb-lg-0">
                                    <li className="list-inline-item">
                                        <Link to="#">Privacy Policy</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#">Terms of Use</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </footer>
    )
}

export default Footer