import React from 'react'

const Singletourdetails = ({ product }) => {
    return (
        <section class="pt-5 bg-light-gray-gradient">
            <div class="container">
                {/* <!-- About --> */}
                <div class="card border-0 shadow-sm mb-5" data-aos="fade">
                    <div class="card-body">
                        <div class="border-bottom mb-4 pb-4">
                            <h2 class="text-body-emphasis mb-0">About</h2>
                        </div>
                        <div class="d-lg-flex border-bottom pb-3 mb-4">
                            <div class="mnw-200">
                                <h3 class="h5">Highlights</h3>
                            </div>
                            <ul class="highlight-list checked">
                                {
                                    product.about.highlight.map((data) => (
                                        <li>
                                            <span><strong>{data.title}:</strong> {data.highlightDescription}</span>
                                        </li>
                                    ))
                                }

                            </ul>
                        </div>
                        <div class="d-lg-flex">
                            <div class="mnw-200">
                                <h3 class="h5">Description</h3>
                            </div>
                            <p>
                                {product.about.description}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <!-- /About --> */}
                {/* <!-- Photos --> */}
                <div class="card border-0 shadow-sm mb-5" data-aos="fade">
                    <div class="card-body">
                        <div class="border-bottom mb-4 pb-4">
                            <h2 class="text-body-emphasis mb-0">
                                Photos
                            </h2>
                        </div>
                        <div class="row g-1 align-items-center">
                            <div class="col-12 col-xl-6">
                                <a href="./assets/img/tours/t7z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                    <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img src="./assets/img/tours/t7.jpg" srcset="./assets/img/tours/t7@2x.jpg 2x" alt="" />
                                        <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                    </figure>
                                </a>
                            </div>
                            <div class="col-12 col-xl-6">
                                <div class="row g-1 align-items-center">
                                    <div class="col-6">
                                        <a href="./assets/img/tours/t8z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                            <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                <img src="./assets/img/tours/t8.jpg" srcset="./assets/img/tours/t8@2x.jpg 2x" alt="" />
                                                <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                            </figure>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="./assets/img/tours/t9z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                            <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                <img src="./assets/img/tours/t9.jpg" srcset="./assets/img/tours/t9@2x.jpg 2x" alt="" />
                                                <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                            </figure>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="./assets/img/tours/t10z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                            <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                <img src="./assets/img/tours/t10.jpg" srcset="./assets/img/tours/t10@2x.jpg 2x" alt="" />
                                                <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                            </figure>
                                        </a>
                                    </div>
                                    <div class="col-6">
                                        <a href="./assets/img/tours/t11z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                            <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                                <img src="./assets/img/tours/t11.jpg" srcset="./assets/img/tours/t11@2x.jpg 2x" alt="" />
                                                <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                            </figure>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-xl-3">
                                <a href="./assets/img/tours/t12z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                    <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img src="./assets/img/tours/t12.jpg" srcset="./assets/img/tours/t12@2x.jpg 2x" alt="" />
                                        <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                    </figure>
                                </a>
                            </div>
                            <div class="col-6 col-xl-3">
                                <a href="./assets/img/tours/t13z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                    <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img src="./assets/img/tours/t13.jpg" srcset="./assets/img/tours/t13@2x.jpg 2x" alt="" />
                                        <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                    </figure>
                                </a>
                            </div>
                            <div class="col-12 col-xl-6">
                                <a href="./assets/img/tours/t14z.jpg" class="glightbox" data-glightbox="title:Explore Noriva Bay" data-gallery="tour-photos">
                                    <figure class="image-hover image-hover-scale image-hover-overlay rounded mb-0">
                                        <img src="./assets/img/tours/t14.jpg" srcset="./assets/img/tours/t14@2x.jpg 2x" alt="" />
                                        <i class="hicon hicon-zoom-bold image-hover-icon fs-5"></i>
                                    </figure>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /Photos --> */}
                {/* <!-- Itinerary --> */}
                <div class="card border-0 shadow-sm mb-5" data-aos="fade">
                    <div class="card-body">
                        <div class="border-bottom mb-4 pb-4">
                            <h2 class="text-body-emphasis mb-0">
                                Itinerary
                            </h2>
                        </div>
                        <p>
                            {product.iteracy.iteracyDescription}
                        </p>
                        <div class="accordion accordion-flush accordion-itinerary" id="acdItinerary">
                            {
                                product.iteracy.curriculum.map((data, i) => (
                                    <div class="accordion accordion-flush accordion-itinerary" id="acdItinerary">
                                        <div class="accordion-item bg-transparent">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#acdContent1${i + 1}`} aria-expanded="false" aria-controls="acdContent1">
                                                    <strong class="rounded-1">Day {i + 1}:</strong>
                                                    <span>{data.title}</span>
                                                </button>
                                            </h2>
                                            <div id={`acdContent1${i + 1}`} class="accordion-collapse collapse" data-bs-parent="#acdItinerary">
                                                <div class="accordion-body">
                                                    {data.curriculumDescription}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* <!-- /Itinerary --> */}
                {/* <!-- Policy --> */}
                <div class="card border-0 shadow-sm mb-5" data-aos="fade">
                    <div class="card-body">
                        <div class="border-bottom mb-4 pb-4">
                            <h2 class="text-body-emphasis mb-0">
                                Policy
                            </h2>
                        </div>
                        <div class="d-lg-flex border-bottom pb-3 mb-4">
                            <div class="mnw-200">
                                <h3 class="h5">Price included</h3>
                            </div>
                            <div>
                                <ul class="highlight-list checked">
                                    {
                                        product.policy.priceIncluded.map((data) => (
                                            data.checked &&
                                            <li>
                                                <span><strong>{data.title}:</strong> {data.description}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <ul class="highlight-list unchecked">
                                    {
                                        product.policy.priceIncluded.map((data) => (
                                            !data.checked &&
                                            <li>
                                                <span><strong>{data.title}:</strong> {data.description}</span>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div class="d-lg-flex border-bottom pb-3 mb-4">
                            <div class="mnw-200">
                                <h3 class="h5">For children</h3>
                            </div>
                            <ul class="highlight-list checked">
                                {
                                    product.policy.forChildren.map((data) => (
                                        data.checked &&
                                        <li>
                                            <span><strong>{data.title}:</strong> {data.description}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div class="d-lg-flex">
                            <div class="mnw-200">
                                <h3 class="h5">Other terms</h3>
                            </div>
                            <p>
                                {product.policy.OtherTerms}
                            </p>
                        </div>
                    </div>
                </div>
                {/* <!-- /Policy --> */}
            </div>
        </section>)
}

export default Singletourdetails