import React from 'react'

const Whychooseus = () => {
    return (
        <section class="hero" data-aos="fade">
            <div class="hero-bg">
                <img src="./assets/img/background/b3.jpg" srcset="./assets/img/background/b3@2x.jpg 2x" alt="" />
            </div>
            <div class="container">
                <div class="p-top-90 p-bottom-90">
                    <div class="row align-items-xl-end mb-4">
                        <div class="col-12 col-xl-6">
                            <div class="block-title me-auto">
                                <small class="sub-title">Great experience</small>
                                <h2 class="h1 title text-white">Why choose us</h2>
                            </div>
                        </div>
                        <div class="col-12 col-xl-6">
                            <p class="text-white">We are a leading travel agency in Moliva with many years of experience, highly rated and appreciated by tourists.</p>
                        </div>
                    </div>
                    <div class="why-slider splide splide__pagination__start w-100 mb-5 mb-xl-0">
                        <div class="splide__track pt-2 pb-2">
                            <ul class="splide__list">
                                <li class="splide__slide">
                                    <div class="transparent-card rounded shadow hover-effect">
                                        <div class="card-icon">
                                            <i class="hicon hicon-family-with-teens"></i>
                                        </div>
                                        <h3 class="h5 card-title">Leading travel agency in Moliva</h3>
                                        <p class="card-desc">
                                            Top-rated agency in Moliva is renowned for exceptional service and unforgettable travel experiences.
                                        </p>
                                    </div>
                                </li>
                                <li class="splide__slide">
                                    <div class="transparent-card rounded shadow-sm hover-effect">
                                        <div class="card-icon">
                                            <i class="hicon hicon-regular-travel-protection"></i>
                                        </div>
                                        <h3 class="h5 card-title">Years of experience in tour operations</h3>
                                        <p class="card-desc">
                                            With years of expertise, we excel at organizing seamless, enjoyable, and memorable tours for every traveler.
                                        </p>
                                    </div>
                                </li>
                                <li class="splide__slide">
                                    <div class="transparent-card rounded shadow-sm hover-effect">
                                        <div class="card-icon">
                                            <i class="hicon hicon-tours"></i>
                                        </div>
                                        <h3 class="h5 card-title">Flexible tour packages and bookings</h3>
                                        <p class="card-desc">
                                            We offer customizable trips with flexible packages and convenient booking options tailored to your needs.
                                        </p>
                                    </div>
                                </li>
                                <li class="splide__slide">
                                    <div class="transparent-card rounded shadow-sm hover-effect">
                                        <div class="card-icon">
                                            <i class="hicon hicon-tours"></i>
                                        </div>
                                        <h3 class="h5 card-title">Best prices with attractive Offers</h3>
                                        <p class="card-desc">
                                            Enjoy unbeatable prices and exclusive offers, ensuring you receive great value with memorable vacation.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Whychooseus