import React from 'react'
import Tourpackegestitle from '../components/Tourpackegestitle'
import Tourpackegestours from '../components/Tourpackegestours'
import Destinationsabout from '../components/Destinationsabout'
import Travelinsight from '../components/Travelinsight'

const Tourpackeges = () => {


    return (

        <main>
            <Tourpackegestitle />
            <Tourpackegestours />
            <Destinationsabout />
            <Travelinsight />
        </main>
    )
}

export default Tourpackeges