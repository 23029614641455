import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../redux/productSlice';
import { setUserData, addToCart, removeFromCart } from '../redux/userSlice';
import axios from 'axios';

const Tourpackegestours = () => {
    const [selectedDestination, setSelectedDestination] = useState(""); // To store selected destination
    const [selectedCategory, setSelectedCategory] = useState(""); // To store selected category
    const [errorMessage, setErrorMessage] = useState(""); // State to store error message
    const [successMessage, setSuccessMessage] = useState("");


    const loggedIn = useSelector((state) => state.user.isLoggedIn);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.userData);

    console.log("User Data: ", userData);
    console.log("Is Logged In: ", loggedIn);

    const { products, loading, error } = useSelector((state) => state.product);

    useEffect(() => {
        dispatch(fetchProducts());
        if (loggedIn) {
            axios.get("/user/api/getUserData")
                .then(response => {
                    dispatch(setUserData(response.data.userData));
                })
                .catch(error => {
                    console.error("Error fetching user data:", error.message);
                });
        }
    }, [dispatch, loggedIn]);

    // Handle product selection based on dropdown choice
    const filteredProducts = products.filter(product => {
        const matchesDestination = selectedDestination ? product._id === selectedDestination : true;
        const matchesCategory = selectedCategory ? product.typeTour === selectedCategory : true;
        return matchesDestination && matchesCategory;
    });

    const getImageSrc = (picture) => {
        if (!picture) {
            return '../../assets/img/avatars/a3@2x.jpg'; // Default image
        }
        return picture.startsWith('http') ? picture : picture;
    };

    const onLikeProduct = async (productId) => {
        if (!loggedIn) {
            navigate("/login");
            return;
        }

        try {
            const response = await axios.post("/user/api/likedProduct", { productId });
            console.log(response.data.message);

            if (response.data.userData) {
                dispatch(setUserData(response.data.userData));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            } else {
                dispatch(addToCart(productId));
                setSuccessMessage("Product liked and added to your wishlist successfully!");
                setErrorMessage("");
            }
        } catch (error) {
            // console.error("Error liking the product:", error.response ? error.response.data.message : error.message);
            if (error.response) {
                if (error.response.status === 409) {
                    // Handle the case where the product is already liked
                    try {
                        const response = await axios.post("/user/api/unlikedProduct", { productId });
                        console.log(response.data.message);

                        // Assuming the API returns the updated userData
                        if (response.data.userData) {
                            dispatch(setUserData(response.data.userData));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        } else {
                            // If the API doesn't return userData, manually update the cart
                            dispatch(removeFromCart(productId));
                            setSuccessMessage("Product removed from your wishlist successfully!");
                        }
                    } catch (error) {
                        console.error("Error disliking the product:", error.response ? error.response.data.message : error.message);
                    }
                } else {
                    // Handle other errors
                    setErrorMessage(error.response.data.message);
                    setSuccessMessage("");
                }
            } else {
                setErrorMessage(error.message); // Handle network or other errors
                setSuccessMessage("");
            }

            console.error("Error liking the product:", errorMessage);
        }
    };

    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient">
            <div className="container">
                <div className="mb-4" data-aos="fade">
                    <div className="row g-0 g-md-2 align-items-end">
                        <div className="col-12 col-xl-2 col-md-6">
                            <select
                                className="form-select shadow-sm dropdown-select me-3 mb-3"
                                aria-label="Select Destination"
                                value={selectedDestination}
                                onChange={(e) => setSelectedDestination(e.target.value)}
                            >
                                <option value="">All destinations</option>
                                {products.map((product) => (
                                    <option key={product._id} value={product._id}>
                                        {product.name} ({product.iteracy.curriculum.length} days)
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-xl-3 col-md-6">
                            <select
                                className="form-select shadow-sm dropdown-select me-3 mb-3"
                                aria-label="Select Category"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value="">All categories</option>
                                {products.map((product) => (
                                    <option key={product._id} value={product.typeTour}>
                                        {product.typeTour} ({product.about.highlight.length} Destinations)
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {successMessage && (
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}
                    {!error && !loading && filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                            <div className="col-12 col-xl-3 col-md-6" key={product._id} data-aos="fade">
                                <div className="card border-0 shadow-sm hover-effect mb-4">
                                    <Link to="/single-tour/" state={{ product, products }}>
                                        <figure className="image-hover image-hover-overlay rounded-top mb-0">
                                            <img src={getImageSrc(product.image[0].imageAdress)} alt={product.name} />
                                            <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                        </figure>
                                    </Link>
                                    <div className="card-body position-relative">
                                        <div className="mb-4">
                                            <h3 className="h4">
                                                <Link to="/single-tour" state={{ product, products }} className="link-dark link-hover">
                                                    {product.name}
                                                </Link>
                                            </h3>
                                            <div className="fsm-1">
                                                <span className="me-2"><i className="hicon hicon-menu-calendar"></i> {product.iteracy.curriculum.length} days</span>
                                                <span className="me-2"><i className="hicon hicon-flights-pin"></i> {product.about.highlight.length} Destinations</span>
                                            </div>
                                        </div>
                                        <div className="inline-review mb-4">
                                            <span className="review-score">4.9</span>
                                            <span className="star-rate-view star-rate-size-sm me-2"><span className="star-value rate-50"></span></span>
                                            <small className="review-total"><span>(231 reviews)</span></small>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="me-auto">
                                                <strong className="fs-4 fw-semibold"><sup>$</sup>{product.price}</strong>
                                                <span className="fsm-4 text-secondary"><sup>$</sup><del>500.00</del></span>
                                            </div>
                                            <Link to="/single-tour" state={{ product, products }} className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-one-ways"></i>
                                            </Link>
                                        </div>
                                        <div className="position-absolute top-0 end-0 translate-middle-y me-4">
                                            <button
                                                onClick={() => onLikeProduct(product._id)}
                                                type="button"
                                                id={`likeButton${product._id}`}
                                                style={{ color: userData?.cart?.includes(product._id) ? '#0077B3' : 'grey' }}
                                                className="circle-icon like-icon liked shadow-sm"
                                            >
                                                <i className="hicon hicon-favorite-filled"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>Data is loading or no products match the selected criteria...</p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Tourpackegestours;
