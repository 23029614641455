import React, { useEffect } from 'react';
import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/splide.min.css';
const Travelinsight = () => {

    useEffect(() => {
        const splide = new Splide('#travelinsight-slider', {
            perPage: 3,
            gap: '1.5rem',
            padding: 0,
            speed: 1000, // Improved readability by changing 1e3 to 1000
            type: 'loop',
            focus: 0,
            breakpoints: {
                1200: {
                    perPage: 1,
                    padding: { left: 0, right: '280px' },
                    arrows: false,
                },
                992: {
                    perPage: 1,
                    padding: { left: 0, right: '250px' },
                    arrows: false,
                },
                576: {
                    perPage: 1,
                    gap: '0.75rem',
                    padding: 0,
                    arrows: false,
                },
            },
        });

        splide.on('mounted', () => {
            const destinationSlider = document.getElementById('travelinsight-slider');


            if (destinationSlider) destinationSlider.style.visibility = 'visible';
        });

        splide.mount();
    }, []);

    return (
        <section className="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div className="container">
                <div className="blog-slider splide__pagination__start splide w-100" id='travelinsight-slider'>
                    <div className="d-xl-flex align-items-xl-center mb-3">
                        <div className="block-title me-auto">
                            <small className="sub-title">Useful information</small>
                            <h2 className="h1 title">Moliva Travel Insight</h2>
                        </div>
                        <div className="d-lg-flex align-items-lg mt-3">
                            <div className="extra-info me-md-5">
                                <strong>+500</strong>
                                <span>Useful articles about Moliva</span>
                            </div>
                            <div className="splide__arrows splide__arrows__right">
                                <button
                                    className="splide__arrow splide__arrow--prev me-2"
                                    style={{ backgroundColor: "#0a3e59", height: "40px", width: "40px" }}                                >
                                    <i className="hicon hicon-edge-arrow-left"></i>
                                </button>
                                <button
                                    className="splide__arrow splide__arrow--next"
                                    style={{ backgroundColor: "#0a3e59", height: "40px", width: "40px" }}                                >
                                    <i className="hicon hicon-edge-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="splide__track pt-2 pb-2">
                        <ul className="splide__list">
                            <li className="splide__slide">
                                <div className="float-card hover-effect">
                                    <div className="position-absolute top-0 start-0 pt-3 ps-3 z-1">
                                        <span className="badge bg-black bg-opacity-50 text-white fw-normal fsm-4 rounded-1">Destinations</span>
                                    </div>
                                    <a href="./single-post.html">
                                        <figure className="image-hover image-hover-overlay rounded mb-0">
                                            <img src="assets/img/blog/b1.jpg" srcSet="assets/img/blog/b1@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                    <div className="card-content shadow-sm rounded">
                                        <h3 className="h5 mb-2">
                                            <a href="./single-post.html" className="link-dark link-hover">
                                                Top 10 Must-Visit Tourist Spots in Moliva
                                            </a>
                                        </h3>
                                        <div className="d-flex align-items-center">
                                            <div className="text-secondary fw-normal fsm-4 me-auto">
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-menu-calendar"></i>
                                                    <span>Jun 30 2024</span>
                                                </div>
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-chat"></i>
                                                    <span>36</span>
                                                </div>
                                                <div className="d-inline-block">
                                                    <i className="hicon hicon-installment-graph"></i>
                                                    <span>754</span>
                                                </div>
                                            </div>
                                            <a href="./single-post.html" className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-one-ways fs-4"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="float-card hover-effect">
                                    <div className="position-absolute top-0 start-0 pt-3 ps-3 z-1">
                                        <span className="badge bg-black bg-opacity-50 text-white fw-normal fsm-4 rounded-1">Hotel & Resort</span>
                                    </div>
                                    <a href="./single-post.html">
                                        <figure className="image-hover image-hover-overlay rounded mb-0">
                                            <img src="assets/img/blog/b2.jpg" srcSet="assets/img/blog/b2@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                    <div className="card-content shadow-sm rounded">
                                        <h3 className="h5 mb-2">
                                            <a href="./single-post.html" className="link-dark link-hover">
                                                5 Best Tips for an Amazing Moliva Trip
                                            </a>
                                        </h3>
                                        <div className="d-flex align-items-center">
                                            <div className="text-secondary fw-normal fsm-4 me-auto">
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-menu-calendar"></i>
                                                    <span>Jun 30 2024</span>
                                                </div>
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-chat"></i>
                                                    <span>36</span>
                                                </div>
                                                <div className="d-inline-block">
                                                    <i className="hicon hicon-installment-graph"></i>
                                                    <span>754</span>
                                                </div>
                                            </div>
                                            <a href="./single-post.html" className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-one-ways fs-4"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="float-card hover-effect">
                                    <div className="position-absolute top-0 start-0 pt-3 ps-3 z-1">
                                        <span className="badge bg-black bg-opacity-50 text-white fw-normal fsm-4 rounded-1">Travel Guide</span>
                                    </div>
                                    <a href="./single-post.html">
                                        <figure className="image-hover image-hover-overlay rounded mb-0">
                                            <img src="assets/img/blog/b3.jpg" srcSet="assets/img/blog/b3@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                    <div className="card-content shadow-sm rounded">
                                        <h3 className="h5 mb-2">
                                            <a href="./single-post.html" className="link-dark link-hover">
                                                8 Key Essentials for Traveling to Moliva
                                            </a>
                                        </h3>
                                        <div className="d-flex align-items-center">
                                            <div className="text-secondary fw-normal fsm-4 me-auto">
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-menu-calendar"></i>
                                                    <span>Jun 30 2024</span>
                                                </div>
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-chat"></i>
                                                    <span>36</span>
                                                </div>
                                                <div className="d-inline-block">
                                                    <i className="hicon hicon-installment-graph"></i>
                                                    <span>754</span>
                                                </div>
                                            </div>
                                            <a href="./single-post.html" className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-one-ways fs-4"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="splide__slide">
                                <div className="float-card hover-effect">
                                    <div className="position-absolute top-0 start-0 pt-3 ps-3 z-1">
                                        <span className="badge bg-black bg-opacity-50 text-white fw-normal fsm-4 rounded-1">Activities</span>
                                    </div>
                                    <a href="./single-post.html">
                                        <figure className="image-hover image-hover-overlay rounded mb-0">
                                            <img src="assets/img/blog/b4.jpg" srcSet="assets/img/blog/b4@2x.jpg 2x" alt="" />
                                            <i className="hicon hicon-plus-thin image-hover-icon fs-5"></i>
                                        </figure>
                                    </a>
                                    <div className="card-content shadow-sm rounded">
                                        <h3 className="h5">
                                            <a href="./single-post.html" className="link-dark link-hover">
                                                Discover Beautiful Moliva: Nature's Paradise
                                            </a>
                                        </h3>
                                        <div className="d-flex align-items-center">
                                            <div className="text-secondary fw-normal fsm-4 me-auto">
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-menu-calendar"></i>
                                                    <span>Jun 30 2024</span>
                                                </div>
                                                <div className="d-inline-block me-2">
                                                    <i className="hicon hicon-chat"></i>
                                                    <span>36</span>
                                                </div>
                                                <div className="d-inline-block">
                                                    <i className="hicon hicon-installment-graph"></i>
                                                    <span>754</span>
                                                </div>
                                            </div>
                                            <a href="./single-post.html" className="circle-icon circle-icon-link">
                                                <i className="hicon hicon-flights-one-ways fs-4"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-md-end" data-aos="fade">
                    <p className="fw-normal text-secondary mb-0 pt-4 mt-5 mt-md-0">
                        <a href="./post-list.html" className="fw-medium">
                            <span>View all posts</span>
                            <i className="hicon hicon-flights-one-ways"></i>
                        </a>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Travelinsight