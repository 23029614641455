import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addToOrders, setUserData } from '../redux/userSlice';

const Singlebooktour = ({ product }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedIn = useSelector((state) => state.user.isLoggedIn);

    const [formData, setFormData] = useState({
        departureDate: '',
        adults: 1,  // Change to number
        children: 0, // Change to number
        productId: product._id,
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const userData = useSelector((state) => state.user.userData);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === "adults" || name === "children" ? Number(value) : value, // Convert to number if it's adults or children
        }));
    };

    const handleBookTour = async (e) => {


        if (!loggedIn) {
            navigate("/login");
            return;
        }
        e.preventDefault();
        setError('');
        setSuccess('');
        // console.log("formData", formData);
        try {
            if (userData.orders.some(order => order.product === formData.productId)) {
                setError("You have already booked this tour.");
            }
            else {
                const response = await axios.post('/user/api/bookingtours', formData, {
                    validateStatus: function (status) {
                        return status >= 200 && status < 400;
                    }
                });

                setSuccess('Tour booked successfully! Proceed to checkout.');
                setError('');
                navigate('/checkout');

                const userdata = response.data?.userData;
                if (userdata) {
                    dispatch(setUserData({
                        ...userdata,
                        isLoggedIn: true,
                    })); // Check if structure matches
                } else {
                    dispatch(addToOrders(formData));
                }
            }
        } catch (error) {
            console.error('Full error object:', error);
            console.error('Error details:', error.response?.data);

            if (error.response) {
                setError(error.response.data.message || 'Failed to book the tour. Please try again.');
            } else if (error.request) {
                setError('No response from server. Please try again later.');
            } else {
                setError(`Error in booking tour. kuchh to error hai ${error}.`);
            }
            setSuccess('');
        }
    };


    useEffect(() => {
    }, [userData]);

    return (
        <section id="book-tour" className="hero" data-aos="fade">
            <div className="hero-bg">
                <img src="./assets/img/tours/t17.jpg" srcSet="./assets/img/tours/t17@2x.jpg 2x" alt="" />
            </div>
            <div className="container">
                <div className="p-top-90 p-bottom-90">
                    <div className="row g-0 justify-content-between">
                        <div className="col-12 col-xl-6">
                            <div className="text-white mb-5 mb-xl-0">
                                <div className="block-title">
                                    <small className="sub-title">Fast and easy</small>
                                    <h2 className="h1 title">Book this tour</h2>
                                </div>
                                <p>
                                    Book your unforgettable Noriva adventure today! For any questions or personalized advice, don’t hesitate to contact us.
                                </p>
                                <div className="mt-4 fw-medium">
                                    <h3 className="h4">You have questions?</h3>
                                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                                        <Link to="#" className="link-light link-hover me-4 mb-3">
                                            <i className="hicon hicon-bold hicon-email-envelope"></i>
                                            <span>Booking@example.com</span>
                                        </Link>
                                        <Link to="#" className="link-light link-hover me-4 mb-3">
                                            <i className="hicon hicon-bold hicon-telephone"></i>
                                            <span>+33 (0) 1 89 78 67 56</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-5">
                            <div className="card border-0 shadow p-lg-2 bg-light-gray">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <span>From:</span>
                                        <strong className="fs-2 fw-semibold fw-medium text-body-emphasis"><sup>$</sup>{product.price}</strong>
                                        <span className="text-uppercase text-secondary"><sup>$</sup><del className="">230.00</del></span>
                                    </div>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {success && <div className="alert alert-success">{success}</div>}
                                    <form className="row g-3" onSubmit={handleBookTour}>
                                        <div className="col-12">
                                            <div className="mb-0">
                                                <div className="input-icon-group tour-date">
                                                    <label className="input-icon hicon hicon-menu-calendar hicon-bold" htmlFor="departureDate"></label>
                                                    <input
                                                        id="departureDate"
                                                        name="departureDate"
                                                        type="date"
                                                        className="form-select shadow-sm"
                                                        placeholder="YEAR-MM-DD"
                                                        value={formData.departureDate}
                                                        onChange={handleInputChange}
                                                        required
                                                        min={new Date().toISOString().split("T")[0]} // Set min to current date
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="input-icon-group">
                                                <label className="input-icon hicon hicon-adults-line hicon-bold" htmlFor="adults"></label>
                                                <select
                                                    className="form-select dropdown-select shadow-sm"
                                                    id="adults"
                                                    name="adults"
                                                    value={formData.adults}
                                                    onChange={handleInputChange}
                                                >
                                                    {Array.from({ length: 10 }, (_, i) => (
                                                        <option key={i + 1} value={i + 1}>{i + 1} adult{(i > 0 ? 's' : '')}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="input-icon-group">
                                                <label className="input-icon hicon hicon-child-line hicon-bold" htmlFor="children"></label>
                                                <select
                                                    className="form-select dropdown-select shadow-sm"
                                                    id="children"
                                                    name="children"
                                                    value={formData.children}
                                                    onChange={handleInputChange}
                                                >
                                                    {Array.from({ length: 10 }, (_, i) => (
                                                        <option key={i} value={i}>{i} child{(i > 1 ? 'ren' : '')}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mb-0">
                                                <button type="submit" className="btn btn-primary w-100 fw-medium">
                                                    <i className="hicon hicon-mmb-my-booking hicon-md mr-1"></i>
                                                    <span>Book now</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Singlebooktour;
