import React from 'react';
import Singletourhero from '../components/Singletourhero';
import Singletourfeature from '../components/Singletourfeature';
import Singletourdetails from '../components/Singletourdetails';
import Singlebooktour from '../components/Singlebooktour';
import Singletourreview from '../components/Singletourreview';
import { Othertours } from '../components/Othertours';
import { useLocation } from 'react-router-dom';

const Singletour = () => {
    const location = useLocation();
    // console.log("Location object:", location); // Debug: Check what's inside location

    const { product, products } = location.state || {}; // Retrieve the product data

    if (!product) {
        // console.log("Product not found");
        return <p>Product not found.</p>;
    }
    const filterProducts = products.filter((item) => item._id !== product._id);
    // console.log(filterProducts);


    return (
        <>
            <Singletourhero product={product} />
            <Singletourfeature product={product} />
            <Singletourdetails product={product} />
            <Singlebooktour product={product} />
            <Singletourreview product={product} />
            <Othertours filterProducts={filterProducts} product={product} products={products} title="Other Tours" subtitle="Maybe you like " />
        </>
    );
};

export default Singletour;
