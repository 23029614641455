import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Import useSelector
import { logout } from '../redux/userSlice';
import { adminlogout } from '../redux/adminSlice';
import axios from 'axios';
import { useDispatch } from 'react-redux';

const Header = ({ loggedIn, adminLoggedIn, setAdminLoggedIn }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const adminData = useSelector((state) => state.admin.adminData);

    const handleLogout = async () => {
        try {
            // Make a request to the logout endpoint using axios
            await axios.post('/user/api/logout', {}, {
                withCredentials: true, // Ensure cookies are sent with the request
            });

            // Update the state to reflect the logout
            dispatch(logout()); // Assuming you have a logout action in your Redux slice
            dispatch(adminlogout()); // Assuming you have a logout action in your Redux slice
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    }


    return (
        <header id="header" data-aos="fade">

            <div className="header-topbar">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-6 col-xl-7 col-md-8">
                            <div className="d-flex align-items-center">
                                <Link to="tel:+84966704132">
                                    <i className="hicon hicon-telephone me-1"></i>
                                    <span>  </span>
                                </Link>
                                <span className="vr bg-white d-none d-md-inline ms-3 me-3"></span>
                                <Link to="mailto:" className="d-none d-md-inline">
                                    <i className="hicon hicon-email-envelope me-1"></i>
                                    <span>Booking@example.com</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-6 col-xl-5 col-md-4">
                            <div className="text-end">
                                <Link className="d-inline-flex align-items-center me-3" data-bs-toggle="modal" href="#mdlLanguage">
                                    <img src="assets/img/flags/en.svg" height="14" className="me-1" alt="" />
                                    <span className="me-1">English</span>
                                    <i className="hicon hicon-thin-arrow-down hicon-bold hicon-60"></i>
                                </Link>
                                <Link className="d-inline-flex align-items-center" data-bs-toggle="modal" href="#mdlCurrency">
                                    <span className="me-1">USD</span>
                                    <i className="hicon hicon-thin-arrow-down hicon-bold hicon-60"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-navbar">
                <nav className="navbar navbar-expand-xl">
                    <div className="container">
                        <button className="navbar-toggler me-3" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <i className="hicon hicon-bold hicon-hamburger-menu"></i>
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img src="assets/img/logos/logo.png" srcSet="assets/img/logos/logo@2x.png 2x" alt="" />
                        </Link>
                        <div className="offcanvas offcanvas-navbar offcanvas-start border-end-0" tabIndex="-1" id="offcanvasNavbar">
                            <div className="offcanvas-header border-bottom p-4 p-xl-0">
                                <Link to="/" className="d-inline-block">
                                    <img src="assets/img/logos/menu-logo.png" srcSet="assets/img/logos/menu-logo@2x.png 2x" alt="" />
                                </Link>
                                <button type="button" className="btn-close shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body p-4 p-xl-0">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle-hover active" to="/" data-bs-display="static">
                                            <span>Home</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle-hover" to="/destinations" data-bs-display="static">
                                            <span>Destinations</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle-hover" to="/tour-packeges" data-bs-display="static">
                                            <span>Tour Packages</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle-hover" href="post-list.html" data-bs-display="static">
                                            <span>Hotels</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle-hover" href="#" data-bs-display="static">
                                            <span>Cabs</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle-hover" href="https://boatwale.com/" target='_blank' rel="noopener noreferrer" data-bs-display="static">
                                            <span>Boats</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="d-flex align-items-center ms-auto">
                                    {!loggedIn ? (
                                        <>
                                            <Link to="/shopping-cart" className="circle-icon cart-icon me-4">
                                                <i className="hicon hicon-bold hicon-shopping-markets"></i>
                                                <span>0</span> {/* Hardcoded value for logged in user */}
                                            </Link>
                                            <Link to="wishlist" className="circle-icon wishlist-icon me-4">
                                                <i className="hicon hicon-bold hicon-menu-favorite"></i>
                                                <span>0</span> {/* Hardcoded value for logged in user */}
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <Link to="/shopping-cart" className="circle-icon cart-icon me-4">
                                                <i className="hicon hicon-bold hicon-shopping-markets"></i>
                                                <span>{userData?.orders?.length || 0}</span> {/* Use userData.order.length when not logged in */}
                                            </Link>
                                            <Link to="wishlist" className="circle-icon wishlist-icon me-4">
                                                <i className="hicon hicon-bold hicon-menu-favorite"></i>
                                                <span>{userData?.cart?.length || 0}</span> {/* Use userData.cart.length when not logged in */}
                                            </Link>
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className="dropdown user-menu ms-auto">
                            <button className="circle-icon circle-icon-link circle-icon-link-hover" data-bs-toggle="dropdown" data-bs-display="static">
                                <i className="hicon hicon-mmb-account"></i>
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end animate slideIn" data-bs-popper="static">
                                {adminLoggedIn ? (
                                    <>
                                        <li>
                                            <Link className="dropdown-item" to="/adminpanel">Admin Panel</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/dashboard">{adminData.fullname}</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" onClick={handleLogout}>Logout</Link>
                                        </li>

                                        <li>
                                            <Link className="dropdown-item" href="./new-password.html">New Password</Link>
                                        </li>
                                    </>
                                ) : loggedIn ? (
                                    <>
                                        <li>
                                            <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/dashboard"> {userData.fullname}</Link >
                                        </li>

                                        <li>
                                            <Link className="dropdown-item" onClick={handleLogout}>Logout</Link>
                                        </li>

                                        <li>
                                            <Link className="dropdown-item" href="./new-password.html">New Password</Link>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li>
                                            <Link className="dropdown-item" to="/login">Login</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="/register">Register</Link>
                                        </li>
                                    </>
                                )}
                                <li>
                                    <Link className="dropdown-item" href="./forgot-password.html">Forgot Password</Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
