import React from 'react'
import Aboutustitle from '../components/Aboutustitle'
import Featured from "../components/Featured"
import About from '../components/About'
import Whychooseus from '../components/Whychooseus'
import Meetourteam from '../components/Meetourteam'
import Testimonials from '../components/Testimonials'

const Aboutus = () => {
    return (
        <main>
            <Aboutustitle />
            <Featured />
            <About />
            <Whychooseus />
            <Meetourteam />
            <Testimonials />
        </main>
    )
}

export default Aboutus