import React, { useState } from 'react';
import UserProfileCard from '../components/UserProfileCard';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { addProduct } from '../redux/productSlice';


const AddProduct = ({ adminLoggedIn, loggedIn, setAdminLoggedIn, setLoggedIn }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [formData, setFormData] = useState({
        name: '',
        price: '',
        highlights: [{ title: '', highlightDescription: '' }],
        description: '',
        iteracyDescription: '',
        curriculums: [{ title: '', curriculumDescription: '' }],
        priceIncluded: [{ checked: false, title: '', description: '' }],
        forChildren: [{ checked: false, title: '', description: '' }],
        otherTerms: '',
        images: [],
        days: 0,
        location: 0,
        tourCode: '',
        startLocation: '',
        endLocation: '',
        typeTour: '',
        ageRange: '',
        language: '',
        payments: '',
        isCancel: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };
    const handleArrayChange = (field, index, event) => {
        const values = [...formData[field]];
        const { name, type, checked, value } = event.target;
        values[index][name] = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [field]: values });
    };

    const addArrayField = (field) => {
        let newItem;
        if (field === 'highlights') {
            newItem = { title: '', highlightDescription: '' };
        } else if (field === 'curriculums') {
            newItem = { title: '', curriculumDescription: '' };
        } else if (field === 'priceIncluded' || field === 'forChildren') {
            newItem = { checked: false, title: '', description: '' };
        }
        setFormData({ ...formData, [field]: [...formData[field], newItem] });
    };
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevFormData) => {
            const updatedImages = [...prevFormData.images, ...files];
            console.log('Selected files:', updatedImages); // Logging the correct, updated state
            return { ...prevFormData, images: updatedImages };
        });
    };


    const addImageField = () => {
        setFormData({ ...formData, images: [...formData.images, ''] });
    };
    const handleImageRemove = (index) => {
        const newImages = formData.images.filter((_, i) => i !== index);
        setFormData({ ...formData, images: newImages });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData);

        const formDataToSend = new FormData();

        // Append form fields to FormData
        for (const key in formData) {
            if (Array.isArray(formData[key])) {
                if (key === 'images') {
                    // Append each image file directly
                    formData[key].forEach((file) => {
                        formDataToSend.append('images', file);
                    });
                } else {
                    formData[key].forEach((item, index) => {
                        for (const subKey in item) {
                            formDataToSend.append(`${key}[${index}][${subKey}]`, item[subKey]);
                        }
                    });
                }
            } else {
                formDataToSend.append(key, formData[key]);
            }
        }
        for (const pair of formDataToSend.entries()) {
            console.log(` files of images are : ${pair[0]}: ${pair[1] instanceof File ? pair[1].name : pair[1]}`);
        }
        try {
            const response = await axios.post('/admin/api/addproduct', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const newProduct = response.data; // Assuming the API returns the added product
            dispatch(addProduct(newProduct));

            // Reset form state after successful submission
            setFormData({
                name: '',
                price: '',
                highlights: [{ title: '', highlightDescription: '' }],
                description: '',
                iteracyDescription: '',
                curriculums: [{ title: '', curriculumDescription: '' }],
                priceIncluded: [{ checked: false, title: '', description: '' }],
                forChildren: [{ checked: false, title: '', description: '' }],
                otherTerms: '',
                images: [],
                days: 0,
                location: 0,
                tourCode: '',
                startLocation: '',
                endLocation: '',
                typeTour: '',
                ageRange: '',
                language: '',
                payments: '',
                isCancel: false,
            });

            navigate('/addproduct');
            ; // Adjust as needed

        } catch (error) {
            console.error("There was an error adding the product!", error.message);
            setErrorMessage("There was an error adding the product!"); // Show an error message
        }
    };


    return (
        <main>
            <div className="p-top-90 p-bottom-90 bg-gray-gradient" data-aos="fade">
                {/* <!-- Title --> */}
                <section className="container">
                    <div className="d-lg-flex align-items-lg-end pb-4">
                        <div className="block-title me-auto">
                            <small className="sub-title">My Account</small>
                            <h1 className="display-5 title">Settings</h1>
                        </div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                {/* <!-- /Title --> */}

                {/* <!-- Settings --> */}
                <section className="container">
                    <div className="row g-0 g-xl-8">
                        <div className="col-12 col-xl-9">
                            {/* <!-- Your Profile --> */}
                            <div className="pe-xl-4 me-xl-2">
                                <div className="card border-0 shadow-sm">
                                    <div className="card-body">
                                        <div className="d-flex align-items-center justify-content-between border-bottom pb-4 mb-4">
                                            <h2 className="h3 text-body-emphasis mb-0">Your profile</h2>
                                        </div>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <form className="form-container" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label>Images:</label>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={handleImageChange}
                                                    name='images'
                                                />
                                                <button type="button" className="btn btn-secondary mt-2" onClick={addImageField}>
                                                    Add More Images
                                                </button>
                                                {formData.images.map((image, index) => (
                                                    <div key={index}>
                                                        <span>{image.name || 'Image selected'}</span>
                                                        <button type="button" onClick={() => handleImageRemove(index)}>Remove</button>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="form-group">
                                                <label>Name:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Price:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="price"
                                                    value={formData.price}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Location:</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    name="location"
                                                    value={formData.location}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Tour Code:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="tourCode"
                                                    value={formData.tourCode}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Start Location:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="startLocation"
                                                    value={formData.startLocation}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>End Location:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="endLocation"
                                                    value={formData.endLocation}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Type of Tour:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="typeTour"
                                                    value={formData.typeTour}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Age Range:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="ageRange"
                                                    value={formData.ageRange}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Language:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="language"
                                                    value={formData.language}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Payments:</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="payments"
                                                    value={formData.payments}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Cancel:</label>
                                                <input
                                                    type="checkbox"
                                                    name="isCancel"
                                                    checked={formData.isCancel}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Highlights:</label>
                                                {formData.highlights.map((highlight, index) => (
                                                    <div key={index}>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={highlight.title}
                                                            onChange={(event) => handleArrayChange('highlights', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="highlightDescription"
                                                            placeholder="Highlight Description"
                                                            value={highlight.highlightDescription}
                                                            onChange={(event) => handleArrayChange('highlights', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('highlights')}>
                                                    Add Another Highlight
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Description:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="description"
                                                    value={formData.description}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Iteracy Description:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="iteracyDescription"
                                                    value={formData.iteracyDescription}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Curriculum:</label>
                                                {formData.curriculums.map((curriculum, index) => (
                                                    <div key={index}>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={curriculum.title}
                                                            onChange={(event) => handleArrayChange('curriculums', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="curriculumDescription"
                                                            placeholder="Curriculum Description"
                                                            value={curriculum.curriculumDescription}
                                                            onChange={(event) => handleArrayChange('curriculums', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('curriculums')}>
                                                    Add Another Curriculum
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Price Included:</label>
                                                {formData.priceIncluded.map((price, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            checked={price.checked}
                                                            onChange={(event) => handleArrayChange('priceIncluded', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={price.title}
                                                            onChange={(event) => handleArrayChange('priceIncluded', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={price.description}
                                                            onChange={(event) => handleArrayChange('priceIncluded', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('priceIncluded')}>
                                                    Add Another Price Included
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>For Children:</label>
                                                {formData.forChildren.map((child, index) => (
                                                    <div key={index}>
                                                        <input
                                                            type="checkbox"
                                                            name="checked"
                                                            checked={child.checked}
                                                            onChange={(event) => handleArrayChange('forChildren', index, event)}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={child.title}
                                                            onChange={(event) => handleArrayChange('forChildren', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="description"
                                                            placeholder="Description"
                                                            value={child.description}
                                                            onChange={(event) => handleArrayChange('forChildren', index, event)}
                                                            style={{ marginBottom: "10px" }}
                                                        />
                                                    </div>
                                                ))}
                                                <button type="button" className="btn btn-primary" onClick={() => addArrayField('forChildren')}>
                                                    Add Another For Children
                                                </button>
                                            </div>

                                            <div className="form-group">
                                                <label>Other Terms:</label>
                                                <textarea
                                                    className="form-control"
                                                    name="otherTerms"
                                                    value={formData.otherTerms}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Your Profile --> */}
                        </div>
                        <div className="col-12 col-xl-3">
                            {/* <!-- Account menu --> */}
                            <div className="card border-0 shadow-sm sticky-top sticky-top-120 d-none d-xl-block z-0">
                                <UserProfileCard adminLoggedIn={adminLoggedIn} loggedIn={loggedIn} setAdminLoggedIn={setAdminLoggedIn} setLoggedIn={setLoggedIn} />
                            </div>
                            {/* <!-- Account menu --> */}
                        </div>
                    </div>
                </section>
                {/* <!-- /Settings --> */}
            </div>
        </main>
    );
};

export default AddProduct;
