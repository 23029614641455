import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children, loggedIn, adminLoggedIn }) {
    const location = useLocation();

    // List of public routes
    const publicRoutes = ["/", "/login", "/register", "/admin", "/adminregister", "/destinations", "/tour-packeges", "/single-tour", "/contact-us"];

    // Check if the current path is in the list of public routes
    const isPublicRoute = publicRoutes.includes(location.pathname);

    // If the route is public, render the component
    // If the route is private and the user is not logged in (and not an admin), redirect to login
    return loggedIn || adminLoggedIn || isPublicRoute ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
