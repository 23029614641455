import React from 'react'

const Videos = () => {
    return (
        <section className="hero" data-aos="fade">
            <div className="hero-bg">
                <img src="assets/img/background/b1.jpg" srcSet="assets/img/background/b1@2x.jpg 2x" alt="" />
            </div>
            <div className="container">
                <div className="p-top-90 p-bottom-90">
                    <div className="video-box rounded">
                        <div className="block-title text-center me-auto">
                            <small className="sub-title text-light">Moliva video</small>
                            <h2 className="h1 title text-white">Beautiful & Romantic</h2>
                        </div>
                        <div className="text-center">
                            <a className="btn-video-play media-glightbox" href="assets/media/v1.mp4"><span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Videos