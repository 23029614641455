import React from 'react'
import { Link } from 'react-router-dom'

const Meetourteam = () => {
    return (
        <section class="p-top-90 p-bottom-90 bg-light-gray-gradient" data-aos="fade">
            <div class="container">
                <div class="team-slider splide splide__pagination__start w-100 mb-5">
                    {/* <!-- Title --> */}
                    <div class="d-xl-flex align-items-xl-center mb-3">
                        <div class="block-title me-auto">
                            <small class="sub-title">Enthusiastic people</small>
                            <h2 class="h1 title">Meet our team</h2>
                        </div>
                        <div class="splide__arrows splide__arrows__right mt-3">
                            <button class="splide__arrow splide__arrow--prev me-2">
                                <i class="hicon hicon-edge-arrow-left"></i>
                            </button>
                            <button class="splide__arrow splide__arrow--next">
                                <i class="hicon hicon-edge-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    {/* <!-- /Title -->
                    <!-- Team --> */}
                    <div class="splide__track pt-2 pb-2">
                        <ul class="splide__list">
                            <li class="splide__slide">
                                <div class="team shadow-sm rounded hover-effect">
                                    <img src="./assets/img/team/t2.jpg" srcset="./assets/img/team/t2@2x.jpg 2x" alt="" />
                                    <div class="team-info">
                                        <h3 class="h5 team-name">John Doe</h3>
                                        <span class="team-city">CEO</span>
                                        <ul class="social-list d-inline-block mb-0">
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                                                        <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M8 11l0 5"></path>
                                                        <path d="M8 8l0 .01"></path>
                                                        <path d="M12 16l0 -5"></path>
                                                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 20l4 -9"></path>
                                                        <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="splide__slide">
                                <div class="team shadow-sm rounded hover-effect">
                                    <img src="./assets/img/team/t1.jpg" srcset="./assets/img/team/t1@2x.jpg 2x" alt="" />
                                    <div class="team-info">
                                        <h3 class="h5 team-name">Emily Smith</h3>
                                        <span class="team-city">Marketing Manager</span>
                                        <ul class="social-list d-inline-block mb-0">
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                                                        <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M8 11l0 5"></path>
                                                        <path d="M8 8l0 .01"></path>
                                                        <path d="M12 16l0 -5"></path>
                                                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 20l4 -9"></path>
                                                        <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="splide__slide">
                                <div class="team shadow-sm rounded hover-effect">
                                    <img src="./assets/img/team/t3.jpg" srcset="./assets/img/team/t3@2x.jpg 2x" alt="" />
                                    <div class="team-info">
                                        <h3 class="h5 team-name">Alex Mark</h3>
                                        <span class="team-city">Tour guide</span>
                                        <ul class="social-list d-inline-block mb-0">
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                                                        <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M8 11l0 5"></path>
                                                        <path d="M8 8l0 .01"></path>
                                                        <path d="M12 16l0 -5"></path>
                                                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 20l4 -9"></path>
                                                        <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="splide__slide">
                                <div class="team shadow-sm rounded hover-effect">
                                    <img src="./assets/img/team/t5.jpg" srcset="./assets/img/team/t5@2x.jpg 2x" alt="" />
                                    <div class="team-info">
                                        <h3 class="h5 team-name">Tom Cruise</h3>
                                        <span class="team-city">Financial management</span>
                                        <ul class="social-list d-inline-block mb-0">
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                                                        <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M8 11l0 5"></path>
                                                        <path d="M8 8l0 .01"></path>
                                                        <path d="M12 16l0 -5"></path>
                                                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 20l4 -9"></path>
                                                        <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li class="splide__slide">
                                <div class="team shadow-sm rounded hover-effect">
                                    <img src="./assets/img/team/t4.jpg" srcset="./assets/img/team/t4@2x.jpg 2x" alt="" />
                                    <div class="team-info">
                                        <h3 class="h5 team-name">Anna Lee</h3>
                                        <span class="team-city">Tour Operator</span>
                                        <ul class="social-list d-inline-block mb-0">
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4l11.733 16h4.267l-11.733 -16z"></path>
                                                        <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                        <path d="M8 11l0 5"></path>
                                                        <path d="M8 8l0 .01"></path>
                                                        <path d="M12 16l0 -5"></path>
                                                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                            <li class="social-item">
                                                <Link to="#">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="none" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M8 20l4 -9"></path>
                                                        <path d="M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7"></path>
                                                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                                    </svg>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* <!-- /Team --> */}
                </div>
            </div>
        </section>
    )
}

export default Meetourteam