import React from 'react'

const Singletourfeature = ({ product }) => {
    return (
        <section class="pt-5 pb-5 bg-light-gray-gradient" data-aos="fade">
            <div class="container">
                <ul class="list-unstyled row g-3 g-md-4 mb-0">
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-promo-code"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Tour code</small>
                                <h3 class="h5 card-title">{product.tourCode}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-poi-text-search"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Start</small>
                                <h3 class="h5 card-title">{product.startLocation}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-poi"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">End</small>
                                <h3 class="h5 card-title">{product.endLocation}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-travelers"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Type tour</small>
                                <h3 class="h5 card-title">{product.typeTour}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-time-clock"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Age range</small>
                                <h3 class="h5 card-title">{product.ageRange}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-seriously-multilingual"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Language</small>
                                <h3 class="h5 card-title">{product.language}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-installment-payment"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Payments</small>
                                <h3 class="h5 card-title">{product.payments}</h3>
                            </div>
                        </div>
                    </li>
                    <li class="col-12 col-xl-3 col-md-6">
                        <div class="mini-card shadow-sm rounded">
                            <span class="card-icon">
                                <i class="hicon hicon-225 hicon-cancel-booking"></i>
                            </span>
                            <div class="card-content">
                                <small class="card-desc">Cancel tour</small>
                                {product.isCancel ? <h3 class="h5 card-title">No</h3> : <h3 class="h5 card-title">Yes</h3>}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>)
}

export default Singletourfeature