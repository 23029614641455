import React, { useEffect } from 'react';

const Singletourhero = ({ product }) => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top-left corner of the window
    }, [product]);
    const handleBookNowClick = (e) => {
        e.preventDefault(); // Prevent default anchor behavior
        const bookTourSection = document.getElementById('book-tour');
        if (bookTourSection) {
            bookTourSection.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling to the section
        }
    };

    return (
        <section className="hero" data-aos="fade">
            <div className="hero-bg">
                <img src="./assets/img/tours/t16.jpg" srcSet="./assets/img/tours/t16@2x.jpg 2x" alt="" />
            </div>
            <div className="container">
                <div className="p-top-150 p-bottom-150">
                    <div className="hero-desc mb-3">
                        <span className="text-uppercase">From:</span>
                        <strong className="fs-1 text-white"><sup>$</sup>{product.price}</strong>
                        <span className="text-uppercase"><sup>$</sup><del className="">230.00</del></span>
                    </div>
                    <div className="mb-5">
                        <h1 className="display-4 hero-title mb-4">
                            {product.name}
                        </h1>
                        <div className="fs-4 hero-desc">
                            <span className="me-3"><i className="hicon hicon-menu-calendar"></i> {product.iteracy.curriculum.length} days</span>
                            <span className="me-3"><i className="hicon hicon-flights-pin"></i> {product.startLocation}</span>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        {/* Book Now button triggers scroll to booking section */}
                        <a href="#book-tour" className="btn btn-primary mnw-180" onClick={handleBookNowClick}>
                            <i className="hicon hicon hicon-bold hicon-menu-calendar"></i>
                            <span>Book now</span>
                        </a>
                    </div>
                </div>
                <div className="title-info">
                    <div className="title-info-body">
                        <div className="horizontal-review rounded-top">
                            <div className="review-content">
                                <h2 className="review-score">4.9</h2>
                                <div className="review-total">
                                    <span className="star-rate-view"><span className="star-value rate-50"></span></span>
                                    <span><strong className="text-body">2,394</strong> reviews</span>
                                </div>
                            </div>
                            <div className="review-link">
                                <small><a href="#reviews" className="link-dark link-hover">Read all tourist reviews <i className="hicon hicon-sm hicon-flights-one-ways"></i></a></small>
                            </div>
                            <div className="review-label">
                                <small>Excellent</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Singletourhero;
